import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ErrorModal from './ErrorModal';
import LoadingModal from './LoadingModal';

const PasswordResetModal = ({ isOpen, onClose, theme, setUserName, getUserName }) => {

    const [currentPassword, setCurrentPassword] = useState(''); 
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessages, setErrorModalMessages] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [loadingModalMessage, setLoadingModalMessage] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;


    const handleClose = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setShowErrorModal(false);
        setErrorModalMessages([]);
        setShowSuccessMessage(false);
        onClose();
    };

    const handleSubmit = async () => {
        //validate all fields are populated
        if(getUserName.length > 0 && currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0){
            //validate new password and confirm password match
            if(newPassword === confirmPassword){
                setLoadingModalMessage('Resetting password...');
                setShowLoadingModal(true);
                setTimeout(() => {
                    const requestOptions = {
                      method: 'GET'
                    };
                    fetch(BASE_API_URL + '/admin/password/reset?userName=' + getUserName + '&newPassword=' + newPassword + '&oldPassword=' + currentPassword, requestOptions)
                    .then(response => {
                      if(response.ok) {
                        return response.json();
                      }
                      else{
                        throw new Error('Failed to reset password. Please try again later.');
                      }
                    })
                    .then(json => {
                      if(json !== undefined){
                        setShowLoadingModal(false);
                        setLoadingModalMessage('');
                        setConfirmPassword('');
                        setNewPassword('');
                        setCurrentPassword('');
                        if(json.status !== 200){
                          errorModalMessages.push(json.message);
                          setShowErrorModal(true);
                        } 
                        else{
                            setShowSuccessMessage(true);
                        }
                      }
                    })
                    .catch(error => {
                      setShowLoadingModal(false);
                      setLoadingModalMessage('');
                      setConfirmPassword('');
                      setNewPassword('');
                      setCurrentPassword('');
                      errorModalMessages.push(error.message);
                      setShowErrorModal(true);
                      setShowSuccessMessage(false);
                    });
                  }, 1000);
            }
            else {
                setErrorModalMessages(['Passwords do not match.']);
                setShowSuccessMessage(false);
                setShowErrorModal(true);
            }
        }
    };


    return (
        <div>
            <Modal
            show={isOpen}
            className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
            onHide={handleClose}>

                {/* Overlay when ErrorModal || LoadingModal is active */}
                {(showErrorModal || showLoadingModal) && <div className="modal-overlay" />}

                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`reset-form ${theme}`}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input
                                type="text"
                                value={getUserName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                            <label>Current Password:</label>
                            <input
                                type="password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            <label>New Password:</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <label>Confirm Password:</label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {showSuccessMessage && <h5>Password reset successfully.</h5>}
                            <button type="submit" className="submit-button" disabled={getUserName.length === 0 || currentPassword.length === 0 || newPassword.length === 0 || confirmPassword.length === 0} onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <ErrorModal 
                theme={theme}
                isOpen={showErrorModal}
                onClose={() => {setShowErrorModal(false); setErrorModalMessages([]);}}
                errorMessages={errorModalMessages}
            />

            <LoadingModal
                theme={theme}
                isOpen={showLoadingModal}
                onClose={() => setShowLoadingModal(false)}
                message={loadingModalMessage}
            />
        </div>
    );
    
};

export default PasswordResetModal;