// src/Leagues.js
import React, { useState, useEffect } from 'react';
import DropdownMenu from './DropdownMenu'; // Import the reusable dropdown
import { Modal, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorModal from './ErrorModal';
import SearchModal from './SearchModal';
import LoadingBubbles from './LoadingBubbles';

const Leagues = ({ theme, getToken, setToken }) => {
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  const [dropdownProviders, setDropdownProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [internalLeagues, setInternalLeagues] = useState([]);
  const [existingLinkedLeagues, setExistingLinkedLeagues] = useState([]);
  const [selectedProviderLeague, setSelectedProviderLeague] = useState(null);
  const [selectedInternalLeague, setSelectedInternalLeague] = useState(null);
  const [providerLeagues, setProviderLeagues] = useState([]);
  const [linkedLeagues, setLinkedLeagues] = useState([]);
  const [selectedDeletionIndexes, setSelectedDeletionIndexes] = useState([]);
  const [scheduledForDeletion, setScheduledForDeletion] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessages, setErrorModalMessages] = useState([]); 
  const [noProviderDataMessage, setNoProviderDataMessage] = useState('Please select a provider and sport to see leagues.');
  const [noInternalDataMessage, setNoInternalDataMessage] = useState('');
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [searchType, setSearchType] = useState(null);
  const [isRendering, setIsRendering] = useState(false);

  const authorization = 'Bearer ' + getToken();


    // Fetch internal provider data on component mount
    useEffect(() => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };
      fetch(BASE_API_URL + '/provider', requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok) {
          return response.json();
        }
        else {
          throw new Error('Failed to fetch provider data. Please try again later.');
        }
      })
      .then(json => {
        //only set the external providers and the dashboard enabled ones
        const filtered = json.providers.filter(provider => provider.isDashboardEnabled === true && provider.isExternal === true);
        setDropdownProviders(filtered);
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch provider data. Please try again later.']);
        setShowErrorModal(true);
      });
    }, []);

    const handleProviderSelect = async (provider) => {
      setLoadingModalMessage('Loading sports data...');
      setShowLoadingModal(true);
      setSelectedProvider(provider);
      setSelectedInternalLeague(null); // Reset selected internal league on new selection
      setSelectedProviderLeague(null); // Reset selected provider league on new selection
      setSports([]);
      setScheduledForDeletion([]);
      setSelectedSport(null);
      setProviderLeagues([]);
      setInternalLeagues([]);
      setExistingLinkedLeagues([]);
      setSelectedDeletionIndexes([]);
      setEditingIndex(null); // Reset editing index on new selection
      setLinkedLeagues([]);
      setIsRendering(false);

      setTimeout(async () => {
        try {
          // Fetch internal sports data. We only will display mapped sports
          await fetchInternalSports(provider.id);
        } catch (error) {
          console.error('Failed to fetch sports:', error);
        } finally {
          setShowLoadingModal(false); // Hide loading modal
          setLoadingModalMessage(''); // Reset loading modal message
        }
      }, 1000);
    };

    const fetchInternalSports = async (providerId) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };
  
      fetch(BASE_API_URL + '/provider/sport?provider=-1', requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok){
          return response.json();
        }
        else{
          throw new Error('Failed to fetch sports data. Please try again later.');
        }
      })
      .then(json => { 
        const existing = json.sports.filter(sport => sport.mappings !== undefined && sport.mappings?.find(item => item.provider.id === providerId) !== undefined);
        if(existing !== undefined && existing.length > 0) {
          const links = [];
          existing.forEach(element => {
            links.push({id: element.id, name: element.name, providerSportId: element.mappings.find(item => item.provider.id === providerId).id, providerSportName: element.mappings.find(item => item.provider.id === providerId)?.name, isScheduledForDeletion: false});
          });
          setSports(links);
        }
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch internal sports data. Please try again later.']);
        setShowErrorModal(true);
       });
    }

    const handleSportSelect = async (sport) => {
      setLoadingModalMessage('Loading leagues data...');
      setShowLoadingModal(true);
      setSelectedSport(sport);
      setSelectedProviderLeague(null); // Reset selected provider sport on new selection
      setSelectedInternalLeague(null); // Reset selected internal sport on new selection
      setProviderLeagues([]);
      setInternalLeagues([]);
      setScheduledForDeletion([]);
      setExistingLinkedLeagues([]);
      setSelectedDeletionIndexes([]);
      setEditingIndex(null); // Reset editing index on new selection
      setLinkedLeagues([]);
      setIsRendering(true);

      setTimeout(async () => { 
        try {
          // Fetch league data for the selected provider
          await fetchInternalLeagues(selectedProvider.id, sport.id);
          await fetchProviderLeagues(selectedProvider.id, sport.providerSportId);
        } catch (error) {
          console.error('Failed to fetch sports:', error);
        } finally {
          setShowLoadingModal(false); // Hide loading modal
          setLoadingModalMessage(''); // Reset loading modal message
        }
      }, 1000);
    };

    const fetchInternalLeagues = async (providerId, sportId) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };
  
      fetch(BASE_API_URL + '/provider/league?provider=-1&sportId='+sportId, requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok) {
          return response.json();
        }
        else if (response.status === 404){
          setNoInternalDataMessage('No internal data found for this sport.');
        }
        else {
          throw new Error('Failed to fetch internal league data. Please try again later.');
        }
      })
      .then(json => {
        if(json !== undefined && json.leagues !== undefined) {
          setInternalLeagues(json.leagues);
          const existing = json.leagues.filter(league => league.mappings !== undefined && league.mappings?.find(item => item.provider.id === providerId) !== undefined);
          if(existing !== undefined && existing.length > 0) {
            const links = [];
            existing.forEach(element => {
              links.push({id: element.id, name: element.name, providerLeagueId: element.mappings.find(item => item.provider.id === providerId).id, providerLeagueName: element.mappings.find(item => item.provider.id === providerId)?.name, isScheduledForDeletion: false});
            });
            setExistingLinkedLeagues(links);
          }        
        }
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch internal league data. Please try again later.']);
        setShowErrorModal(true);
      });
    }

    const fetchProviderLeagues = async (providerId, sportId) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };
  
      fetch(BASE_API_URL + '/provider/league?provider='+providerId+'&sportId='+sportId, requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok){
          return response.json();
        } else if(response.status === 404){
          setNoProviderDataMessage('No provider data found for this sport.');
        }
        else {
          throw new Error('Failed to fetch provider league data. Please try again later.');
        }
      })
      .then(json => {
        if(json !== undefined && json.leagues !== undefined) {
          setProviderLeagues(json.leagues);
        }
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch provider league data. Please try again later.']);
        setShowErrorModal(true);
      });
    }

    const handleProviderLeagueClick = (league) => {
      setSelectedProviderLeague(selectedProviderLeague && selectedProviderLeague.id === league.id ? null : league);
    };
  
    const handleInternalLeagueClick = (league) => {
      setSelectedInternalLeague(selectedInternalLeague && selectedInternalLeague.id === league.id ? null : league);
    };

    const handleAddLink = () => {
      if (selectedProviderLeague && selectedInternalLeague) {
        // Check for duplicates
        const isDuplicate = linkedLeagues.some(link =>
          link.providerLeague.id === selectedProviderLeague.id || link.internalLeague.id === selectedInternalLeague.id
        );
        if (!isDuplicate) {
          setLinkedLeagues(prevLinks => [
            ...prevLinks,
            { providerLeague: selectedProviderLeague, internalLeague: selectedInternalLeague }
          ]);
          setSelectedProviderLeague(null);
          setSelectedInternalLeague(null);
        } else {
          setShowAlert(true);
        }
      }
    };

    const handleConfirmDeletion = async () => {
      if (scheduledForDeletion.length > 0) {
        setLoadingModalMessage('Deleting linked leagues data...');
        setShowLoadingModal(true);
        setTimeout(async () => {
          //transform scheduledForDeletion to the format required by the API
          const postMessage = [];
          for (let i = 0; i < scheduledForDeletion.length; i++) {
            postMessage.push({ provider: selectedProvider.id, league: Number(scheduledForDeletion[i].id), id: Number(scheduledForDeletion[i].providerLeagueId) });
          }
  
          const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': authorization,
              'content-type': 'application/json'
            },
            body: JSON.stringify({leagues: postMessage})
          };
      
          fetch(BASE_API_URL + '/provider/league/unassign', requestOptions)
          .then(response => {
            if(response.status === 401) {
              setToken(null);
            }
            else if(response.ok) {
              return response.json();
          }
            else {
              throw new Error('Failed to delete linked leagues data. Please try again later.');
            }
          })
          .then(json => {
            setLoadingModalMessage(''); // Reset loading modal message
            if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
              setErrorModalMessages(json.failedAssignments);
              setShowErrorModal(true);
            }
            else{
              handleProviderSelect(selectedProvider);    
            }
          })
          .catch(error => {
            setErrorModalMessages(['Failed to delete linked leagues data. Please try again later.']);
            setShowErrorModal(true);
          });    
        }, 1000);
      }
    }

    const handleScheduleLinkForDeletionClick = (index) => {
      if(scheduledForDeletion.length > 0) {
        //only add the item if it doesn't already exist in the setScheduledForDeletion array
        const obj = existingLinkedLeagues[index];
        const filtered = scheduledForDeletion.filter(item => item.id !== obj.id && item.providerLeagueId !== obj.providerLeagueId);
        setScheduledForDeletion([...filtered, obj]);
      }
      else{
        setScheduledForDeletion([existingLinkedLeagues[index]])
      }
  
      if(selectedDeletionIndexes !== undefined) {
        //add index if it does not already exist
        const doesExist = selectedDeletionIndexes.find(item => item === index);
        if(doesExist === undefined) {
          selectedDeletionIndexes.push(index);
        }
      }
    }
  
    const handleUndoScheduleLinkForDeletionClick = (index) => {
      if(scheduledForDeletion !== undefined && scheduledForDeletion.length > 0) {
        //get the id for the existing linked sport
        const obj = existingLinkedLeagues[index];
        const filtered = scheduledForDeletion.filter(item => item.id !== obj.id && item.providerLeagueId !== obj.providerLeagueId);
        //remove the item with the matching id in the setScheduledForDeletion array
        setScheduledForDeletion(filtered);
        if(selectedDeletionIndexes !== undefined) {
          //add index if it does not already exist
          const indexesFiltered = selectedDeletionIndexes.filter(item => item !== index);
          setSelectedDeletionIndexes(indexesFiltered);
        }
      }
    }

    const handleSubmit = async () => {
      if (linkedLeagues.length > 0) {
        setLoadingModalMessage('Submitting linked leagues data...');
        setShowLoadingModal(true);

        setTimeout(async () => {
          //transform linkedLeagues to the format required by the API
          const postMessage = [];
          for (let i = 0; i < linkedLeagues.length; i++) {
            postMessage.push({ provider: selectedProvider.id, league: Number(linkedLeagues[i].internalLeague.id), id: Number(linkedLeagues[i].providerLeague.id), providerSportId: selectedSport.providerSportId });
          }
  
          const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': authorization,
              'content-type': 'application/json'
            },
            body: JSON.stringify({leagues: postMessage})
          };
      
          fetch(BASE_API_URL + '/provider/league/assign?sportId=' + selectedSport.providerSportId, requestOptions)
          .then(response => {
            if(response.status === 401) {
              setToken(null);
            }
            else if(response.ok) {
              return response.json();       
            }
            else {
              throw new Error('Failed to submit data. Please try again later.');
            }
          })
          .then(json => {
            setShowLoadingModal(false); // Hide loading modal
            setLoadingModalMessage(''); // Reset loading modal message
            if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
              setErrorModalMessages(json.failedAssignments);
              setShowErrorModal(true);
            }
            else{
              handleProviderSelect(selectedProvider);    
            }
          })
          .catch(error => {
            setErrorModalMessages(['Failed to submit data. Please try again later.']);
            setShowErrorModal(true);
          }); 
         }, 1000);
      }
    };

    const handleEditLink = (index) => {
      setEditingIndex(index);
      setSelectedProviderLeague(linkedLeagues[index].providerLeague);
      setSelectedInternalLeague(linkedLeagues[index].internalLeague);
    };
  
    const handleDeleteLink = (index) => {
      setLinkedLeagues(prevLinks => prevLinks.filter((_, i) => i !== index));
      if (editingIndex === index) {
        setEditingIndex(null);
        setSelectedProviderLeague(null);
        setSelectedInternalLeague(null);
      }
    };

    const handleSaveEdit = () => {
      if (editingIndex !== null) {
        setLinkedLeagues(prevLinks => {
          const newLinks = [...prevLinks];
          newLinks[editingIndex] = { providerLeague: selectedProviderLeague, internalLeague: selectedInternalLeague };
          return newLinks;
        });
        setEditingIndex(null);
        setSelectedProviderLeague(null);
        setSelectedInternalLeague(null);
      }
    };

    const handleErrorModalClose = () => {
      setShowErrorModal(false);
      setErrorModalMessages([]);
      if(selectedProvider !== undefined){
        handleProviderSelect(selectedProvider);    
      }
    };

    const handleProviderSearch = () => 
      {
        setSearchType('provider');
        //build search items
        providerLeagues.forEach(item => {
          //check if the item is mapped in the internal sports list
          var mappedItem = internalLeagues?.find(item1 => item1.mappings?.find(item2 => item2.provider !== undefined && item2.provider.id === selectedProvider.id && item2.id === item.id) !== undefined);
          const mappings = [];
          if(mappedItem !== undefined) {
            mappings.push({provider: mappedItem.provider, id: mappedItem.id});
          }
    
          const tempItem = item;
          tempItem.mappings = mappings;
    
          searchItems.push(tempItem);
        });
    
        setShowSearchModal(true);
      };
    
      const handleInternalSearch = () => 
        {
          setSearchType('internal');
          //build search items
          internalLeagues.forEach(item => {
            searchItems.push(item);
          });
      
          setShowSearchModal(true);
        };

        
  const handleSearchItemSelect = (item) => {
    if(searchType === 'provider'){
      setSelectedProviderLeague(item);
    }
    else if(searchType === 'internal'){
      setSelectedInternalLeague(item);
    }

    setSearchItems([]);
    setShowSearchModal(false);
  };

  return (
    <div className={`leagues-container ${theme}`}>
      <div className="provider-container-header">
        <DropdownMenu
          options={dropdownProviders}
          selectedOption={selectedProvider}
          onSelect={handleProviderSelect}
          theme={theme}
        />
        <DropdownMenu
          options={sports}
          selectedOption={selectedSport}
          onSelect={handleSportSelect}
          theme={theme}
          disabled={sports.length === 0}
        />
          <button className={theme === 'dark-mode' ? 'add-link-button dark-mode' : 'add-link-button light-mode'} onClick={handleAddLink} disabled={!selectedProviderLeague || !selectedInternalLeague}>
            Add Link
          </button>
      </div>
      <div className="sports-content">
        <div className="sports-sections">
          <div className="sports-section dynamic-section">
            <div className='section-header'>
              <h3>{selectedProvider ? selectedProvider.name : 'Select a Provider'}</h3>
              <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'}  onClick={handleProviderSearch} disabled={providerLeagues.length === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>              
              </button> 
            </div>
            <div className='section-content'>
              {isRendering && providerLeagues.length === 0 && <LoadingBubbles theme={theme} isDropdown={false}/>}
              {!isRendering && providerLeagues.length === 0 && <p>{noProviderDataMessage}</p>}
              {providerLeagues.length > 0 && (
                <div className="sports-list">
                  {providerLeagues.map((league) => { 
                    var mappedInternalLeagues = internalLeagues?.find(item => item.mappings?.find(item1 => item1.provider !== undefined && item1.provider.id === selectedProvider.id && item1.id === league.id) !== undefined);
                    
                    return (
                      <div
                        key={league.id}
                        className={`sport-item ${mappedInternalLeagues !== undefined ? 'mapped' : selectedProviderLeague && selectedProviderLeague.id === league.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                        onClick={() => handleProviderLeagueClick(league)}
                      >
                        <p>ID: {league.id}</p>
                        <p>Name: {league.name}</p>
                        {/* Conditionally display the internal ID if it exists */}
                        {mappedInternalLeagues && (
                          <p>NBCSports ID: {mappedInternalLeagues.id}</p>
                        )}
                      </div>
                  )})}
                </div>
              )}
            </div>
          </div>          
          <div className="sports-section internal-section">
            <div className='section-header'>
              <h3>NBCSports</h3>
              <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'}  onClick={handleInternalSearch} disabled={internalLeagues.length === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>              
              </button> 
            </div>
            <div className='section-content'>
            {isRendering && internalLeagues.length === 0 && <LoadingBubbles theme={theme} isDropdown={false}/>}
            {!isRendering && internalLeagues.length === 0 && <p>{noInternalDataMessage}</p>}
            {internalLeagues.length > 0 && (
              <div className="sports-list">
                {internalLeagues.map((league) => {
                  // Find the mapped provider league for the selected provider
                  const mappedProviderLeague = league.mappings?.find(item => item.provider.id === selectedProvider?.id);

                  return (
                    <div
                      key={league.id}
                      className={`sport-item ${mappedProviderLeague !== undefined ? 'mapped' : selectedInternalLeague && selectedInternalLeague.id === league.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                      onClick={() => handleInternalLeagueClick(league)}
                    >
                      <p>ID: {league.id}</p>
                      <p>Name: {league.name}</p>
                      {/* Conditionally display the provider ID if it exists */}
                      {mappedProviderLeague && (
                        <p>{selectedProvider.name} ID: {mappedProviderLeague.id}</p>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            </div>
          </div>
        </div>
        {existingLinkedLeagues.length > 0 && (
          <div className="linked-section">
            <div className="linked-section-header">
              <h3>Existing Linked Leagues</h3>
              <button className={theme === 'dark-mode' ? 'delete-existing-link-button dark-mode' : 'delete-existing-link-button light-mode'} onClick={handleConfirmDeletion} disabled={scheduledForDeletion.length === 0}>
                {"Delete Exisiting Links"}
              </button>
            </div>
            <div className="linked-section-content">
              {existingLinkedLeagues.map((link, index) => (
                <div className={`linked-item ${selectedDeletionIndexes.find(item => item === index) !== undefined ? 'scheduled' :  ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`} key={index}>
                  <div className="linked-item-details">
                      <p>
                        <b>
                          <i>NBCSports League:</i>
                        </b>{" "}
                        {link.name}
                      </p>
                      <p>
                        <b>
                          <i>{selectedProvider.name} ID:</i>
                        </b>{" "}
                        {link.providerLeagueId}
                      </p>
                      <p>
                        <b>
                          <i>NBCSports ID:</i>
                        </b>{" "}
                        {link.id}
                      </p>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleScheduleLinkForDeletionClick(index)}>Delete</button>
                    <button onClick={() => handleUndoScheduleLinkForDeletionClick(index)}>Undo</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
          <div className="linked-section">
            <div className="linked-section-header">
              <h3>New Links</h3>
              <button className={theme === 'dark-mode' ? 'submit-button dark-mode' : 'submit-button light-mode'} onClick={handleSubmit} disabled={linkedLeagues.length === 0}>
                {"Submit"}
              </button>
            </div>
            <div className="linked-section-content">
              {linkedLeagues.length === 0 && <p>No linked leagues yet.</p>}
              {linkedLeagues.map((link, index) => (
                <div className="linked-item" key={index}>
                  <div className="linked-item-details">
                    <div>
                      <p><b><i>{selectedProvider.name} League:</i></b> {link.providerLeague.name}</p>
                      <p><b><i>NBCSports League:</i></b> {link.internalLeague.name}</p>
                    </div>
                    <div>
                      <p><b><i>{selectedProvider.name} ID:</i></b> {link.providerLeague.id}</p>
                      <p><b><i>NBCSports ID:</i></b> {link.internalLeague.id}</p>
                    </div>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleEditLink(index)}>Edit</button>
                    <button onClick={() => handleDeleteLink(index)}>Delete</button>
                  </div>
                </div>
              ))}
              {editingIndex !== null && (
                <button className="save-edit-button" onClick={handleSaveEdit}>
                  Save Edit
                </button>
              )}
            </div>
        </div>
      </div>
      {/* Loading Modal */}
      <Modal
        show={showLoadingModal}
        onHide={() => setShowLoadingModal(false)}
        backdrop="static"
        keyboard={false}
        className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
      >
        <Modal.Header>
          <Modal.Title>{loadingModalMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>        
        </Modal.Body>
      </Modal>

      {/* Modal for duplicate alert */}
      <Modal show={showAlert} onHide={() => setShowAlert(false)} className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>League is already linked. Please select a different pair of leagues.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlert(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal 
        isOpen={showErrorModal} 
        onClose={handleErrorModalClose} 
        errorMessages={errorModalMessages} 
        theme={theme} />

      <SearchModal 
        isOpen={showSearchModal} 
        onClose={() => {setShowSearchModal(false); setSearchItems([]);}} 
        targetProvider={searchType === 'internal' ? { name: 'NBCSports', id: -1} : selectedProvider}
        searchItems={searchItems}
        theme={theme}
        handleSearchItemSelect={handleSearchItemSelect} />

    </div>
  );
};

export default Leagues;
