      import React from 'react';
      import { Modal, Spinner } from 'react-bootstrap';

      const LoadingModal = ({ isOpen, onClose, message, theme }) => {

        return (
            <div>
                {/* Loading Modal */}
                <Modal
                    show={isOpen}
                    onHide={onClose}
                    backdrop="static"
                    keyboard={false}
                    className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
                >
                    <Modal.Header>
                    <Modal.Title>{message}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>        
                    </Modal.Body>
                </Modal>
            </div>
        );
      }

      export default LoadingModal;
