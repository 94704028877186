// src/Login.js
import React, { useState } from 'react';
import LoadingModal  from './LoadingModal';
import ErrorModal from './ErrorModal';
import PasswordResetModal from './PasswordResetModal';

const Login = ({ theme, setToken }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessages, setErrorModalMessages] = useState([]); 
  const [showResetModal, setShowResetModal] = useState(false);

  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  const fetchToken = async () => {
    setTimeout(() => {
      const requestOptions = {
        method: 'GET'
      };
      fetch(BASE_API_URL + '/admin/authenticate?userName=' + userName + '&password=' + password, requestOptions)
      .then(response => {
        if(response.ok) {
          return response.json();
        }
        else{
          throw new Error('Failed to login. Please try again later.');
        }
      })
      .then(json => {
        if(json !== undefined){
          setShowLoadingModal(false);
          setLoadingModalMessage('');
          setUserName('');
          setPassword('');
          if(json.status !== 200){
            errorModalMessages.push(json.message);
            setShowErrorModal(true);
          } else if (json.token === undefined) {
            errorModalMessages.push('Failed to login. Please try again later.');
            showErrorModal(true);
          }
          else{
            setToken(json.token);
          }
        }
      })
      .catch(error => {
        setShowLoadingModal(false);
        setLoadingModalMessage('');
        errorModalMessages.push(error.message);
        setShowErrorModal(true);
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    if (userName.length > 0 && password.length > 0) {
      setLoadingModalMessage('Logging in...');
      setShowLoadingModal(true);

      await fetchToken();
    }
  };

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
    setErrorModalMessages([]);
  }

  return (
    <div>
    <div className={`login-container ${theme}`}>
      <h2>Login</h2>
      <div className={`login-form ${theme}`}>
        <div className="form-group">
          <label>Username:</label>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='login-page-buttons'>
          <button type="submit" className="submit-button" disabled={userName.length === 0 || password.length === 0} onClick={handleSubmit}>Login</button>
          <button type="submit" className="submit-button" onClick={() => setShowResetModal(true)}>Reset</button>
        </div>
      </div>
    </div>
    <LoadingModal 
        isOpen={showLoadingModal}
        onClose={() => setShowLoadingModal(false)}
        message={loadingModalMessage}
        theme={theme} />

    <ErrorModal 
      isOpen={showErrorModal}
      onClose={handleErrorModalClose}
      errorMessages={errorModalMessages}
      theme={theme} />

    <PasswordResetModal 
      isOpen={showResetModal}
      onClose={() => setShowResetModal(false)}
      theme={theme}
      setUserName={setUserName}
      getUserName={userName}/>
    </div>
  );
};

export default Login;
