// src/Teams.js
import React, { useState, useEffect } from 'react';
import DropdownMenu from './DropdownMenu'; // Import the reusable dropdown
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Ensure styles are applied
import { Modal, Button, Spinner } from 'react-bootstrap';
import ErrorModal from './ErrorModal';
import SearchModal from './SearchModal';
import LoadingBubbles from './LoadingBubbles';

const Teams = ({ theme, getToken, setToken }) => {
  const [dropdownProviders, setDropdownProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessages, setErrorModalMessages] = useState([]); 
  const [leagues, setLeagues] = useState([]);
  const [scheduledForDeletion, setScheduledForDeletion] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedInternalTeam, setSelectedInternalTeam] = useState(null);
  const [selectedProviderTeam, setSelectedProviderTeam] = useState(null);
  const [linkedTeams, setLinkedTeams] = useState([]);
  const [existingLinkedTeams, setExistingLinkedTeams] = useState([]);
  const [providerTeams, setProviderTeams] = useState([]);
  const [internalTeams, setInternalTeams] = useState([]);
  const [selectedDeletionIndexes, setSelectedDeletionIndexes] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [noProviderDataMessage, setNoProviderDataMessage] = useState('Please select a provider, league, and season to see teams.');
  const [noInternalDataMessage, setNoInternalDataMessage] = useState('');
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [searchType, setSearchType] = useState(null);
  const [isRendering, setIsRendering] = useState(false);

  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const authorization = 'Bearer ' + getToken();


    // Fetch internal provider data on component mount
    useEffect(() => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };
      fetch(BASE_API_URL + '/provider', requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok) {
          return response.json();
        }
        else {
          throw new Error('Failed to fetch provider data. Please try again later.');
        }
      })
      .then(json => {
          //only set the external providers and the dashboard enabled ones
          const filtered = json.providers.filter(provider => provider.isDashboardEnabled === true && provider.isExternal === true);
          setDropdownProviders(filtered);
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch provider data. Please try again later.']);
        setShowErrorModal(true);
      });
    }, []);

    const handleProviderSelect = (provider) => {
      setLoadingModalMessage('Loading league data...');
      setShowLoadingModal(true);
      setSelectedProvider(provider);
      setLeagues([]);
      setScheduledForDeletion([]);
      setSelectedLeague(null);
      setSelectedSport(null);
      setSelectedInternalTeam(null);
      setSelectedProviderTeam(null);
      setLinkedTeams([]);
      setExistingLinkedTeams([]);
      setProviderTeams([]);
      setInternalTeams([]);
      setSelectedDeletionIndexes([]);
      setEditingIndex(null); // Reset editing index on new selection
      setSeasons([]);
      setSelectedSeason(null);
      setIsRendering(false);

      //we use a timeout to allow the data to fully load before setting the selected provider
      setTimeout(async () => {
        try {
          // Fetch sports data for the selected provider
          await fetchInternalLeagues(provider.id);
        } catch (error) {
          console.error('Failed to fetch leagues:', error);
        }
        finally {
          setShowLoadingModal(false); // Hide loading modal
          setLoadingModalMessage(''); // Reset loading modal message
        }
      }, 1000);
    };

    const fetchInternalLeagues = async (providerId) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };
  
      fetch(BASE_API_URL + '/provider/league?provider=-1', requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok) {
          return response.json();
        }
        else {
          throw new Error('Failed to fetch internal league data. Please try again later.');
        }
      })
      .then(json => {
        const existing = json.leagues.filter(league => league.mappings !== undefined && league.mappings?.find(item => item.provider.id === providerId) !== undefined && league.seasons !== undefined);
        if(existing !== undefined && existing.length > 0) {
          const links = [];
          existing.forEach(element => {
            links.push({id: element.id, name: element.name, providerLeagueId: element.mappings.find(item => item.provider.id === providerId).id, providerLeagueName: element.mappings.find(item => item.provider.id === providerId)?.name, seasons: element.seasons, sport: element.sport, isScheduledForDeletion: false});
          });
          setLeagues(links);
        }
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch internal league data. Please try again later.']);
        setShowErrorModal(true);
      });
    }

    const handleLeagueSelect = async (league) => {
      setSelectedLeague(league);
      setSelectedSport(league.sport);
      setSelectedProviderTeam(null); // Reset selected provider team on new selection
      setSelectedInternalTeam(null); // Reset selected internal team on new selection
      setProviderTeams([]);
      setInternalTeams([]);
      setScheduledForDeletion([]);
      setExistingLinkedTeams([]);
      setSelectedDeletionIndexes([]);
      setEditingIndex(null); // Reset editing index on new selection
      setLinkedTeams([]);
      setSelectedSeason(null);
      setSeasons([]);
      setIsRendering(false);
      const tempSeasons = [];
      //sort the incoming seasons
      league.seasons.sort();
      //make descending, so latest season is first
      league.seasons.reverse();
      league.seasons.forEach(season => {
        tempSeasons.push({id: season, name: season});
      });
      setSeasons(tempSeasons);
    };

    const handleSeasonSelect = async (season) => {
      setLoadingModalMessage('Loading team data...');
      setShowLoadingModal(true);
      setSelectedProviderTeam(null); // Reset selected provider team on new selection
      setSelectedInternalTeam(null); // Reset selected internal team on new selection
      setProviderTeams([]);
      setInternalTeams([]);
      setScheduledForDeletion([]);
      setExistingLinkedTeams([]);
      setSelectedDeletionIndexes([]);
      setEditingIndex(null); // Reset editing index on new selection
      setLinkedTeams([]);
      setSelectedSeason(season);
      setIsRendering(true);

      //get teams
      setTimeout(async () => {
        try {
          // Fetch teams data for the selected provider
          await fetchInternalTeams(selectedProvider.id, selectedLeague, selectedSport, season);
          await fetchProviderTeams(selectedProvider.id, selectedLeague, selectedSport, season);
        } catch (error) {
          console.error('Failed to fetch teams:', error);
        }
        finally {
          setShowLoadingModal(false); // Hide loading modal
          setLoadingModalMessage(''); // Reset loading modal message
        }
      }, 1000);
    }

    const fetchInternalTeams = async (providerId, league, sport, season) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };
  
      fetch(BASE_API_URL + '/provider/team?provider=-1&season=' + season.id + '&leagueId=' + league.id, requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok) {
          return response.json();
        }
        else if(response.status === 404){
          setInternalTeams([]);
          setNoInternalDataMessage('No internal team data found for the selected league and season.');
        }
        else {
          throw new Error('Failed to fetch internal teams data. Please try again later.');
        }
      })
      .then(json => {
        if(json !== undefined && json.teams !== undefined) {
          setInternalTeams(json.teams);
          const existing = json.teams.filter(team => team.mappings !== undefined && team.mappings?.find(item => item.provider.id === providerId) !== undefined);
          if(existing !== undefined && existing.length > 0) {
            const links = [];
            existing.forEach(element => {
              links.push({id: element.id, name: element.name, providerTeamId: element.mappings.find(item => item.provider.id === providerId).id, providerTeamName: element.mappings.find(item => item.provider.id === providerId)?.name, isScheduledForDeletion: false});
            });
            setExistingLinkedTeams(links);
          }        
        }
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch internal team data. Please try again later.']);
        setShowErrorModal(true);
      });
    }

    const fetchProviderTeams = async (providerId, league, sport, season) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': authorization
        }
      };

      //get the provider sport id
      const providerSportId = Number(league.sport.mappings.find(item => item.provider.id === providerId).id);
      const leagueId = Number(league.providerLeagueId);
  
      fetch(BASE_API_URL + '/provider/team?sportId=' + providerSportId + '&provider=' + providerId + '&leagueId=' + leagueId, requestOptions)
      .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok){
          return response.json();
        } else if(response.status === 404){
          setProviderTeams([]);
          setNoProviderDataMessage('No provider team data found for the selected league and season.');
        }
        else {
          throw new Error('Failed to fetch provider team data. Please try again later.');
        }
      })
      .then(json => {
        if(json !== undefined && json.teams !== undefined) {
          setProviderTeams(json.teams);
        }
      })
      .catch(error => {
        setErrorModalMessages(['Failed to fetch provider team data. Please try again later.']);
        setShowErrorModal(true);
      });
    }

    const handleProviderTeamClick = (team) => {
      setSelectedProviderTeam(selectedProviderTeam && selectedProviderTeam.id === team.id ? null : team);
    };
  
    const handleInternalTeamClick = (team) => {
      setSelectedInternalTeam(selectedInternalTeam && selectedInternalTeam.id === team.id ? null : team);
    };

    const handleConfirmDeletion = async () => {
      if (scheduledForDeletion.length > 0) {
        setLoadingModalMessage('Deleting linked teams data...');
        setShowLoadingModal(true);
        setTimeout(async () => {
          //transform scheduledForDeletion to the format required by the API
          const postMessage = [];

          const providerSportId = Number(selectedSport.mappings.find(item => item.provider.id === selectedProvider.id).id);

          for (let i = 0; i < scheduledForDeletion.length; i++) {
            postMessage.push({ provider: selectedProvider.id, league: Number(selectedLeague.id), internalTeamId: Number(scheduledForDeletion[i].id), id: Number(scheduledForDeletion[i].providerTeamId), providerLeagueId: Number(selectedLeague.providerLeagueId), providerSportId: providerSportId, season: selectedSeason.id });
          }
  
          const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': authorization,
              'content-type': 'application/json'
            },
            body: JSON.stringify({teams: postMessage})
          };
      
          fetch(BASE_API_URL + '/provider/team/unassign', requestOptions)
          .then(response => {
            if(response.status === 401) {
              setToken(null);
            }
            else if(response.ok) {
              return response.json();               
            }
            else {
              throw new Error('Failed to delete linked teams data. Please try again later.');
            }
          })
          .then(json => {
            setShowLoadingModal(false); // Hide loading modal
            setLoadingModalMessage(''); // Reset loading modal message
            if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
              setErrorModalMessages(json.failedAssignments);
              setShowErrorModal(true);
            }
            else{
              handleProviderSelect(selectedProvider);    
            }
          })
          .catch(error => {
            setErrorModalMessages(['Failed to delete linked teams data. Please try again later.']);
            setShowErrorModal(true);
          });    
        }, 1000);
      }
    }

    const handleScheduleLinkForDeletionClick = (index) => {
      if(scheduledForDeletion.length > 0) {
        //only add the item if it doesn't already exist in the setScheduledForDeletion array
        const obj = existingLinkedTeams[index];
        const filtered = scheduledForDeletion.filter(item => item.id !== obj.id && item.providerTeamId !== obj.providerTeamId);
        setScheduledForDeletion([...filtered, obj]);
      }
      else{
        setScheduledForDeletion([existingLinkedTeams[index]])
      }
  
      if(selectedDeletionIndexes !== undefined) {
        //add index if it does not already exist
        const doesExist = selectedDeletionIndexes.find(item => item === index);
        if(doesExist === undefined) {
          selectedDeletionIndexes.push(index);
        }
      }
    }
  
    const handleUndoScheduleLinkForDeletionClick = (index) => {
      if(scheduledForDeletion !== undefined && scheduledForDeletion.length > 0) {
        //get the id for the existing linked sport
        const obj = existingLinkedTeams[index];
        const filtered = scheduledForDeletion.filter(item => item.id !== obj.id && item.providerTeamId !== obj.providerTeamId);
        //remove the item with the matching id in the setScheduledForDeletion array
        setScheduledForDeletion(filtered);
        if(selectedDeletionIndexes !== undefined) {
          //add index if it does not already exist
          const indexesFiltered = selectedDeletionIndexes.filter(item => item !== index);
          setSelectedDeletionIndexes(indexesFiltered);
        }
      }
    }

    const handleSubmit = async () => {
      if (linkedTeams.length > 0) {
        setLoadingModalMessage('Submitting linked teams data...');
        setShowLoadingModal(true);

        setTimeout(async () => {

          const providerSportId = Number(selectedSport.mappings.find(item => item.provider.id === selectedProvider.id).id);

          //transform linkedTeams to the format required by the API
          const postMessage = [];
          for (let i = 0; i < linkedTeams.length; i++) {
            postMessage.push({ provider: selectedProvider.id, league: Number(selectedLeague.id), internalTeamId: Number(linkedTeams[i].internalTeam.id), id: Number(linkedTeams[i].providerTeam.id), providerLeagueId: Number(selectedLeague.providerLeagueId), providerSportId: providerSportId, season: selectedSeason.id });
          }
  
          const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': authorization,
              'content-type': 'application/json'
            },
            body: JSON.stringify({teams: postMessage})
          };
      
          fetch(BASE_API_URL + '/provider/team/assign', requestOptions)
          .then(response => {
            if(response.status === 401) {
              setToken(null);
            }
            else if(response.ok) {
              return response.json();     
            }
            else {
              throw new Error('Failed to submit data. Please try again later.');
            }
          })
          .then(json => {
            setShowLoadingModal(false); // Hide loading modal
            setLoadingModalMessage(''); // Reset loading modal message
            if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
              setErrorModalMessages(json.failedAssignments);
              setShowErrorModal(true);
            }
            else{
              handleProviderSelect(selectedProvider);    
            }
          })
          .catch(error => {
            setErrorModalMessages(['Failed to submit data. Please try again later.']);
            setShowErrorModal(true);
          }); 
         }, 1000);
      }
    };

    const handleEditLink = (index) => {
      setEditingIndex(index);
      setSelectedProviderTeam(linkedTeams[index].providerTeam);
      setSelectedInternalTeam(linkedTeams[index].internalTeam);
    };
  
    const handleDeleteLink = (index) => {
      setLinkedTeams(prevLinks => prevLinks.filter((_, i) => i !== index));
      if (editingIndex === index) {
        setEditingIndex(null);
        setSelectedProviderTeam(null);
        setSelectedInternalTeam(null);
      }
    };

    const handleSaveEdit = () => {
      if (editingIndex !== null) {
        setLinkedTeams(prevLinks => {
          const newLinks = [...prevLinks];
          newLinks[editingIndex] = { providerTeam: selectedProviderTeam, internalTeam: selectedInternalTeam };
          return newLinks;
        });
        setEditingIndex(null);
        setSelectedProviderTeam(null);
        setSelectedInternalTeam(null);
      }
    };

    const handleAddLink = () => {
      if (selectedProviderTeam && selectedInternalTeam) {
        // Check for duplicates
        const isDuplicate = linkedTeams.some(link =>
          link.providerTeam.id === selectedProviderTeam.id || link.internalTeam.id === selectedInternalTeam.id
        );
        if (!isDuplicate) {
          setLinkedTeams(prevLinks => [
            ...prevLinks,
            { providerTeam: selectedProviderTeam, internalTeam: selectedInternalTeam }
          ]);
          setSelectedProviderTeam(null);
          setSelectedInternalTeam(null);
        } else {
          setShowAlert(true);
        }
      }
    };

    const handleErrorModalClose = () => {
      setShowErrorModal(false);
      setErrorModalMessages([]);
      if(selectedProvider !== undefined){
        handleProviderSelect(selectedProvider);    
      }
    }

    const handleProviderSearch = () => 
      {
        setSearchType('provider');
        //build search items
        providerTeams.forEach(item => {
          //check if the item is mapped in the internal sports list
          var mappedItem = internalTeams?.find(item1 => item1.mappings?.find(item2 => item2.provider !== undefined && item2.provider.id === selectedProvider.id && item2.id === item.id) !== undefined);
          const mappings = [];
          if(mappedItem !== undefined) {
            mappings.push({provider: mappedItem.provider, id: mappedItem.id});
          }
    
          const tempItem = item;
          tempItem.mappings = mappings;
    
          searchItems.push(tempItem);
        });
    
        setShowSearchModal(true);
      };
    
      const handleInternalSearch = () => 
        {
          setSearchType('internal');
          //build search items
          internalTeams.forEach(item => {
            searchItems.push(item);
          });
      
          setShowSearchModal(true);
        };

        
  const handleSearchItemSelect = (item) => {
    if(searchType === 'provider'){
      setSelectedProviderTeam(item);
    }
    else if(searchType === 'internal'){
      setSelectedInternalTeam(item);
    }

    setSearchItems([]);
    setShowSearchModal(false);
  };

  return (
    <div className={`teams-container ${theme}`}>
      <div className="provider-container-header">
        <DropdownMenu
          options={dropdownProviders}
          selectedOption={selectedProvider}
          onSelect={handleProviderSelect}
          theme={theme}
          disabled={false}
        />
        <DropdownMenu
          options={leagues}
          selectedOption={selectedLeague}
          onSelect={handleLeagueSelect}
          theme={theme}
          disabled={leagues.length === 0}
        />
        <DropdownMenu
          options={seasons}
          selectedOption={selectedSeason}
          onSelect={handleSeasonSelect}
          theme={theme}
          disabled={seasons.length === 0}
        />
          <button className={theme === 'dark-mode' ? 'add-link-button dark-mode' : 'add-link-button light-mode'} onClick={handleAddLink} disabled={!selectedProviderTeam || !selectedInternalTeam}>
            Add Link
          </button>
      </div>
      <div className="sports-content">
          <div className="sports-sections">
            <div className="sports-section dynamic-section">
              <div className='section-header'>
                <h3>{selectedProvider ? selectedProvider.name : 'Select a Provider'}</h3>
                <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'}  onClick={handleProviderSearch} disabled={providerTeams.length === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>              
              </button> 
              </div>
              <div className='section-content'>
                {isRendering && providerTeams.length === 0 && <LoadingBubbles theme={theme} isDropdown={false}/>}
                {!isRendering && providerTeams.length === 0 && <p>{noProviderDataMessage}</p>}
                {providerTeams.length > 0 && (
                  <div className="sports-list">
                    {providerTeams.map((team) => { 
                      var mappedInternalTeams = internalTeams?.find(item => item.mappings?.find(item1 => item1.provider !== undefined && item1.provider.id === selectedProvider.id && item1.id === team.id) !== undefined);
                      
                      return (
                        <div
                          key={team.id}
                          className={`sport-item ${mappedInternalTeams !== undefined ? 'mapped' : selectedProviderTeam && selectedProviderTeam.id === team.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                          onClick={() => handleProviderTeamClick(team)}
                        >
                          <p>ID: {team.id}</p>
                          <p>Name: {team.name}</p>
                          {/* Conditionally display the internal ID if it exists */}
                          {mappedInternalTeams && (
                            <p>NBCSports ID: {mappedInternalTeams.id}</p>
                          )}
                        </div>
                    )})}
                  </div>
                )}
            </div>
            </div>
            <div className="sports-section internal-section">
              <div className='section-header'>
                <h3>NBCSports</h3>
                <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'}  onClick={handleInternalSearch} disabled={internalTeams.length === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>              
              </button> 
              </div>
              <div className='section-content'>
              {isRendering && internalTeams.length === 0 && <LoadingBubbles theme={theme} isDropdown={false}/> }
              {!isRendering && internalTeams.length === 0 && <p>{noInternalDataMessage}</p>}
              {internalTeams.length > 0 && (
              <div className="sports-list">
                {internalTeams.map((team) => {
                  // Find the mapped provider league for the selected provider
                  const mappedProviderTeam = team.mappings?.find(item => item.provider.id === selectedProvider?.id);

                  return (
                    <div
                      key={team.id}
                      className={`sport-item ${mappedProviderTeam !== undefined ? 'mapped' : selectedInternalTeam && selectedInternalTeam.id === team.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                      onClick={() => handleInternalTeamClick(team)}
                    >
                      <p>ID: {team.id}</p>
                      <p>Name: {team.name}</p>
                      {/* Conditionally display the provider ID if it exists */}
                      {mappedProviderTeam && (
                        <p>{selectedProvider.name} ID: {mappedProviderTeam.id}</p>
                      )}
                    </div>
                  );
                })}
              </div>
              )}
              </div>
            </div>
          </div>
          {existingLinkedTeams.length > 0 && (
          <div className="linked-section">
            <div className="linked-section-header">
              <h3>Existing Linked Teams</h3>
              <button className={theme === 'dark-mode' ? 'delete-existing-link-button dark-mode' : 'delete-existing-link-button light-mode'} onClick={handleConfirmDeletion} disabled={scheduledForDeletion.length === 0}>
                {"Delete Exisiting Links"}
              </button>
            </div>
            <div className="linked-section-content">
              {existingLinkedTeams.map((link, index) => (
                <div className={`linked-item ${selectedDeletionIndexes.find(item => item === index) !== undefined ? 'scheduled' :  ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`} key={index}>
                  <div className="linked-item-details">
                      <p>
                        <b>
                          <i>NBCSports Team:</i>
                        </b>{" "}
                        {link.name}
                      </p>
                      <p>
                        <b>
                          <i>{selectedProvider.name} ID:</i>
                        </b>{" "}
                        {link.providerTeamId}
                      </p>
                      <p>
                        <b>
                          <i>NBCSports ID:</i>
                        </b>{" "}
                        {link.id}
                      </p>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleScheduleLinkForDeletionClick(index)}>Delete</button>
                    <button onClick={() => handleUndoScheduleLinkForDeletionClick(index)}>Undo</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
          <div className="linked-section">
            <div className="linked-section-header">
              <h3>New Links</h3>
              <button className={theme === 'dark-mode' ? 'submit-button dark-mode' : 'submit-button light-mode'} onClick={handleSubmit} disabled={linkedTeams.length === 0}>
                {"Submit"}
              </button>
            </div>
            <div className="linked-section-content">
              {linkedTeams.length === 0 && <p>No linked teams yet.</p>}
              {linkedTeams.map((link, index) => (
                <div className="linked-item" key={index}>
                  <div className="linked-item-details">
                    <div>
                      <p><b><i>{selectedProvider.name} Team:</i></b> {link.providerTeam.name}</p>
                      <p><b><i>NBCSports Team:</i></b> {link.internalTeam.name}</p>
                    </div>
                    <div>
                      <p><b><i>{selectedProvider.name} ID:</i></b> {link.providerTeam.id}</p>
                      <p><b><i>NBCSports ID:</i></b> {link.internalTeam.id}</p>
                    </div>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleEditLink(index)}>Edit</button>
                    <button onClick={() => handleDeleteLink(index)}>Delete</button>
                  </div>
                </div>
              ))}
              {editingIndex !== null && (
                <button className="save-edit-button" onClick={handleSaveEdit}>
                  Save Edit
                </button>
              )}
            </div>
        </div>
      </div>
      {/* Loading Modal */}
      <Modal
        show={showLoadingModal}
        onHide={() => setShowLoadingModal(false)}
        backdrop="static"
        keyboard={false}
        className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
      >
        <Modal.Header>
          <Modal.Title>{loadingModalMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>        
        </Modal.Body>
      </Modal>

      {/* Modal for duplicate alert */}
      <Modal show={showAlert} onHide={() => setShowAlert(false)} className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Team is already linked. Please select a different pair of teams.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlert(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal 
        isOpen={showErrorModal} 
        onClose={handleErrorModalClose} 
        errorMessages={errorModalMessages} 
        theme={theme} />

      <SearchModal 
        isOpen={showSearchModal} 
        onClose={() => {setShowSearchModal(false); setSearchItems([]);}} 
        targetProvider={searchType === 'internal' ? { name: 'NBCSports', id: -1} : selectedProvider}
        searchItems={searchItems}
        theme={theme}
        handleSearchItemSelect={handleSearchItemSelect} />

    </div>
  );
};

export default Teams;
