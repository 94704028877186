const LoadingBubbles = ({theme, isDropdown}) => {
    return (
        <div className="loading-bubbles">
            <div className={`bubble ${isDropdown ? 'dropdown' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}></div>
            <div className={`bubble ${isDropdown ? 'dropdown' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}></div>
            <div className={`bubble ${isDropdown ? 'dropdown' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}></div>
        </div>
    );
};

export default LoadingBubbles;