import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const MarketSearchModal = ({ isOpen, onClose, marketsData, theme }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter the markets based on the search input
  const filteredMarkets = marketsData.filter(event =>
    event.markets.some(market =>
      market.marketType.toLowerCase().includes(searchTerm.toLowerCase()) ||
      market.options.some(option => 
        option.team.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.odds.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
  );

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        setSearchTerm('');
        onClose();
      }}
      className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Market Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="search-bar-container">
          <input
            type="search"
            className={'form-control' + (theme === 'dark-mode' ? ' dark-mode' : '')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="search-list">
          {filteredMarkets.length > 0 ? (
            filteredMarkets.map(event => (
              <div key={event.id} className={`market-item ${theme === 'dark-mode' ? 'dark-mode' : ''}`}>
                <div className='search-item-header'>
                    <p>{event.title}</p>
                    <p>{event.startDateTimeDisplay}</p>
                </div>
                {event.markets.map((market, index) => (
                  <div
                    key={index}
                    className={`search-item ${theme === 'dark-mode' ? 'dark-mode' : ''}`}
                  >
                    <span>{market.marketType}</span>
                    <ul>
                      {market.options.map((option, idx) => (
                        <li key={idx}>
                          {option.team} - Odds: {option.odds} - Value: {option.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <p>No markets found matching the search criteria.</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MarketSearchModal;
