import React, { useState, useEffect } from 'react';
import DropdownMenu from './DropdownMenu'; // Import the reusable dropdown
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Ensure styles are applied
import { Modal, Button } from 'react-bootstrap';
import SearchModal from './SearchModal';
import ErrorModal from './ErrorModal';
import LoadingModal from './LoadingModal';
import LoadingBubbles from './LoadingBubbles';

const Sports = ({ theme, getToken, setToken }) => {
  const [dropdownProviders, setDropdownProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerSports, setProviderSports] = useState([]);
  const [internalSports, setInternalSports] = useState([]);
  const [selectedProviderSport, setSelectedProviderSport] = useState(null);
  const [selectedInternalSport, setSelectedInternalSport] = useState(null);
  const [linkedSports, setLinkedSports] = useState([]);
  const [existingLinkedSports, setExistingLinkedSports] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [scheduledForDeletion, setScheduledForDeletion] = useState([]);
  const [selectedDeletionIndexes, setSelectedDeletionIndexes] = useState([]);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessages, setErrorModalMessages] = useState([]); 
  const [noProviderDataMessage, setNoProviderDataMessage] = useState('Please select a provider to see sports.');
  const [noInternalDataMessage, setNoInternalDataMessage] = useState('');
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [searchType, setSearchType] = useState(null);
  const [isRendering, setIsRendering] = useState(false);

  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const authorization = 'Bearer ' + getToken();

  // Fetch internal provider data on component mount
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authorization
      }
    };
    fetch(BASE_API_URL + '/provider', requestOptions)
    .then(response => {
      if(response.ok) {
        return response.json();
      }
      else if(response.status === 401){
        setToken(null);
      }
      else {
        throw new Error('Failed to fetch provider data. Please try again later.');
      }
    })
    .then(json => {
        if(json !== undefined){
          //only set the external providers and the dashboard enabled ones
          const filtered = json.providers.filter(provider => provider.isDashboardEnabled === true && provider.isExternal === true);
          setDropdownProviders(filtered);
        }
    })
    .catch(error => {
      setErrorModalMessages(['Failed to fetch provider data. Please try again later.']);
      setShowErrorModal(true);
    });
  }, []);

  const handleProviderSelect = (provider) => {
    setLoadingModalMessage('Loading sports data...');
    setShowLoadingModal(true);
    setSelectedProvider(provider);
    setSelectedProviderSport(null); // Reset selected provider sport on new selection
    setSelectedInternalSport(null); // Reset selected internal sport on new selection
    setLinkedSports([]); // Reset linked sports on new selection
    setExistingLinkedSports([]);
    setProviderSports([]);
    setInternalSports([]);
    setScheduledForDeletion([]);
    setSelectedDeletionIndexes([]);
    setEditingIndex(null); // Reset editing index on new selection
    setShowSearchModal(false);
    setIsRendering(true);

    //we use a timeout to allow the data to fully load before setting the selected provider
    setTimeout(async () => {
      try {
        // Fetch sports data for the selected provider
        await fetchInternalSports(provider.id);
        await fetchProviderSports(provider.id);
      } catch (error) {
        console.error('Failed to fetch sports:', error);
      }
      finally {
        setShowLoadingModal(false); // Hide loading modal
        setLoadingModalMessage(''); // Reset loading modal message
      }
    }, 1000);
  };

  const fetchInternalSports = async (providerId) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authorization
      }
    };

    fetch(BASE_API_URL + '/provider/sport?provider=-1', requestOptions)
    .then(response => {
        if(response.status === 401) {
          setToken(null);
        }
        else if(response.ok) {
          return response.json();
        }
        else if (response.status === 404) {
          setNoInternalDataMessage('No internal sports data found.');
        }
        else {
          throw new Error('Failed to fetch internal sports data. Please try again later.');
        }
    })
    .then(json => { 
      if(json !== undefined && json.sports !== undefined) {
        setInternalSports(json.sports);
        const existing = json.sports.filter(sport => sport.mappings !== undefined && sport.mappings?.find(item => item.provider.id === providerId) !== undefined);
        if(existing !== undefined && existing.length > 0) {
          const links = [];
          existing.forEach(element => {
            links.push({internalSportId: element.id, internalSportName: element.name, providerSportId: element.mappings.find(item => item.provider.id === providerId).id, providerSportName: element.mappings.find(item => item.provider.id === providerId)?.name, isScheduledForDeletion: false});
          });
          setExistingLinkedSports(links);
        }
      }
    })
    .catch(error => {
      setErrorModalMessages(['Failed to fetch internal sports data. Please try again later.']);
      setShowErrorModal(true);
     });
  }

  const fetchProviderSports = async (providerId) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': authorization
      }
    };

    fetch(BASE_API_URL + '/provider/sport?provider=' + providerId, requestOptions)
    .then(response => {
      if(response.status === 401) {
        setToken(null);
      }
      else if(response.ok) {
        return response.json();
      }
      else if (response.status === 404) {
        setNoProviderDataMessage('No sports data found for this provider.');
      }
      else {
        throw new Error('Failed to fetch provider sports data. Please try again later.');
      }
    })
    .then(json => {
      if(json !== undefined && json.sports !== undefined) {
        setProviderSports(json.sports);
      }
    })
    .catch(error => {
      setErrorModalMessages(['Failed to fetch provider sports data. Please try again later.']);
      setShowErrorModal(true);
    });
  }

  const handleProviderSportClick = (sport) => {
    setSelectedProviderSport(selectedProviderSport && selectedProviderSport.id === sport.id ? null : sport);
  };

  const handleInternalSportClick = (sport) => {
    setSelectedInternalSport(selectedInternalSport && selectedInternalSport.id === sport.id ? null : sport);
  };

  const handleAddLink = () => {
    if (selectedProviderSport && selectedInternalSport) {
      // Check for duplicates
      const isDuplicate = linkedSports.some(link =>
        link.providerSport.id === selectedProviderSport.id || link.internalSport.id === selectedInternalSport.id
      );
      if (!isDuplicate) {
        setLinkedSports(prevLinks => [
          ...prevLinks,
          { providerSport: selectedProviderSport, internalSport: selectedInternalSport }
        ]);
        setSelectedProviderSport(null);
        setSelectedInternalSport(null);
      } else {
        setShowAlert(true);
      }
    }
  };

  const handleEditLink = (index) => {
    setEditingIndex(index);
    setSelectedProviderSport(linkedSports[index].providerSport);
    setSelectedInternalSport(linkedSports[index].internalSport);
  };

  const handleDeleteLink = (index) => {
    setLinkedSports(prevLinks => prevLinks.filter((_, i) => i !== index));
    if (editingIndex === index) {
      setEditingIndex(null);
      setSelectedProviderSport(null);
      setSelectedInternalSport(null);
    }
  };

  const handleConfirmDeletion = async () => {

    if(scheduledForDeletion.length > 0){
      setLoadingModalMessage('Deleting linked sports data...');
      setShowLoadingModal(true);
      setTimeout(() => {
          //transform scheduledForDeletion to the format required by the API
          const postMessage = [];
          for (let i = 0; i < scheduledForDeletion.length; i++) {
            postMessage.push({ provider: selectedProvider.id, sport: Number(scheduledForDeletion[i].internalSportId), id: Number(scheduledForDeletion[i].providerSportId) });
          }
  
          const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': authorization,
              'content-type': 'application/json'
            },
            body: JSON.stringify({sports: postMessage})
          };
      
          fetch(BASE_API_URL + '/provider/sport/unassign', requestOptions)
          .then(response => {
            if(response.status === 401) {
              setToken(null);
            }
            else if(response.ok) {
              return response.json();
            }
            else {
              throw new Error('Failed to delete linked sports data. Please try again later.');
            }
          })
          .then(json => {
            setShowLoadingModal(false); // Hide loading modal
            setLoadingModalMessage(''); // Reset loading modal message
            if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
              setErrorModalMessages(json.failedAssignments);
              setShowErrorModal(true);
            }
            else{
              handleProviderSelect(selectedProvider);
            }
          })
          .catch(error => {
            setErrorModalMessages(['Failed to delete linked sports data. Please try again later.']);
            setShowErrorModal(true);
          });    
      }, 1000);
    }
  }
  
  const handleScheduleLinkForDeletionClick = (index) => {
    if(scheduledForDeletion.length > 0) {
      //only add the item if it doesn't already exist in the setScheduledForDeletion array
      const obj = existingLinkedSports[index];
      const filtered = scheduledForDeletion.filter(item => item.internalSportId !== obj.internalSportId && item.providerSportId !== obj.providerSportId);
      setScheduledForDeletion([...filtered, obj]);
    }
    else{
      setScheduledForDeletion([existingLinkedSports[index]])
    }

    if(selectedDeletionIndexes !== undefined) {
      //add index if it does not already exist
      const doesExist = selectedDeletionIndexes.find(item => item === index);
      if(doesExist === undefined) {
        selectedDeletionIndexes.push(index);
      }
    }
  }

  const handleUndoScheduleLinkForDeletionClick = (index) => {
    if(scheduledForDeletion !== undefined && scheduledForDeletion.length > 0) {
      //get the id for the existing linked sport
      const obj = existingLinkedSports[index];
      const filtered = scheduledForDeletion.filter(item => item.internalSportId !== obj.internalSportId && item.providerSportId !== obj.providerSportId);
      //remove the item with the matching id in the setScheduledForDeletion array
      setScheduledForDeletion(filtered);
      if(selectedDeletionIndexes !== undefined) {
        //add index if it does not already exist
        const indexesFiltered = selectedDeletionIndexes.filter(item => item !== index);
        setSelectedDeletionIndexes(indexesFiltered);
      }
    }
  }

  const handleSaveEdit = () => {
    if (editingIndex !== null) {
      setLinkedSports(prevLinks => {
        const newLinks = [...prevLinks];
        newLinks[editingIndex] = { providerSport: selectedProviderSport, internalSport: selectedInternalSport };
        return newLinks;
      });
      setEditingIndex(null);
      setSelectedProviderSport(null);
      setSelectedInternalSport(null);
    }
  };

  const handleSubmit = async () => {
    if (linkedSports.length > 0) {
      setLoadingModalMessage('Submitting linked sports data...');
      setShowLoadingModal(true);

      setTimeout(() => {
          //transform linkedSports to the format required by the API
          const postMessage = [];
          for (let i = 0; i < linkedSports.length; i++) {
            postMessage.push({ provider: selectedProvider.id, sport: Number(linkedSports[i].internalSport.id), id: Number(linkedSports[i].providerSport.id) });
          }
  
          const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': authorization,
              'content-type': 'application/json'
            },
            body: JSON.stringify({sports: postMessage})
          };
      
          fetch(BASE_API_URL + '/provider/sport/assign', requestOptions)
          .then(response => {
            if(response.status === 401) {
              setToken(null);
            }
            else if(response.ok) {
              return response.json();        
            }
            else {
              throw new Error('Failed to submit data. Please try again later.');
            }
          }
        )
        .then(json => {
          setShowLoadingModal(false); // Hide loading modal
          setLoadingModalMessage(''); // Reset loading modal message
          if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
            setErrorModalMessages(json.failedAssignments);
            setShowErrorModal(true);
          }
          else {
            handleProviderSelect(selectedProvider);    
          }
        })
        .catch(error => {
          setErrorModalMessages(['Failed to submit data. Please try again later.']);
          setShowErrorModal(true);
        }); 
      }, 1000);
    }
  };

  const handleSearchItemSelect = (item) => {
    if(searchType === 'provider'){
      setSelectedProviderSport(item);
    }
    else if(searchType === 'internal'){
      setSelectedInternalSport(item);
    }

    setSearchItems([]);
    setShowSearchModal(false);
  };

  const handleProviderSearch = () => 
  {
    setSearchType('provider');
    //build search items
    providerSports.forEach(item => {
      //check if the item is mapped in the internal sports list
      var mappedItem = internalSports?.find(item1 => item1.mappings?.find(item2 => item2.provider !== undefined && item2.provider.id === selectedProvider.id && item2.id === item.id) !== undefined);
      const mappings = [];
      if(mappedItem !== undefined) {
        mappings.push({provider: mappedItem.provider, id: mappedItem.id});
      }

      const tempItem = item;
      tempItem.mappings = mappings;

      searchItems.push(tempItem);
    });

    setShowSearchModal(true);
  };

  const handleInternalSearch = () => 
    {
      setSearchType('internal');
      //build search items
      internalSports.forEach(item => {
        searchItems.push(item);
      });
  
      setShowSearchModal(true);
    };

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
    setErrorModalMessages([]);
    if(selectedProvider !== undefined){
      handleProviderSelect(selectedProvider);    
    }
  }

  return (
    <div className={`sports-container ${theme}`}>
      <div className="provider-container-header">
        <DropdownMenu
          options={dropdownProviders}
          selectedOption={selectedProvider}
          onSelect={handleProviderSelect}
          theme={theme}
          disabled={false}
        />
          <button className={theme === 'dark-mode' ? 'add-link-button dark-mode' : 'add-link-button light-mode'}  onClick={handleAddLink} disabled={!selectedProviderSport || !selectedInternalSport}>
            Add Link
          </button>
      </div>
      <div className="sports-content">
        <div className="sports-sections">
          <div className="sports-section dynamic-section">
            <div className='section-header'>
              <h3>{selectedProvider ? selectedProvider.name : 'Select a Provider'}</h3>
              <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'} onClick={handleProviderSearch} disabled={providerSports.length === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>              
              </button> 
            </div>
            <div className='section-content'>
              {isRendering && providerSports.length === 0 && <LoadingBubbles theme={theme} isDropdown={false} />}
              {!isRendering && providerSports.length === 0 && <p>{noProviderDataMessage}</p>}
              {providerSports.length > 0 && (
                <div className="sports-list">
                  {providerSports.map((sport) => { 
                    var mappedInternalSport = internalSports?.find(item => item.mappings?.find(item1 => item1.provider !== undefined && item1.provider.id === selectedProvider.id && item1.id === sport.id) !== undefined);
                    
                    return (
                      <div
                        key={sport.id}
                        className={`sport-item ${mappedInternalSport !== undefined ? 'mapped' : selectedProviderSport && selectedProviderSport.id === sport.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                        onClick={() => handleProviderSportClick(sport)}
                      >
                        <p>ID: {sport.id}</p>
                        <p>Name: {sport.name}</p>
                        {/* Conditionally display the internal ID if it exists */}
                        {mappedInternalSport && (
                          <p>NBCSports ID: {mappedInternalSport.id}</p>
                        )}
                      </div>
                  )})}
                </div>
              )}
            </div>
          </div>
          <div className="sports-section internal-section">
            <div className='section-header'>
              <h3>NBCSports</h3>
              <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'}  onClick={handleInternalSearch} disabled={internalSports.length === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>              
              </button> 
            </div>
            <div className='section-content'>
            {isRendering && internalSports.length === 0 && <LoadingBubbles theme={theme} isDropdown={false} />}
            {!isRendering && internalSports.length === 0 && <p>{noInternalDataMessage}</p>}
            {internalSports.length > 0 && (
              <div className="sports-list">
                {internalSports.map((sport) => {
                  // Find the mapped provider sport for the selected provider
                  const mappedProviderSport = sport.mappings?.find(item => item.provider.id === selectedProvider?.id);

                  return (
                    <div
                      key={sport.id}
                      className={`sport-item ${mappedProviderSport !== undefined ? 'mapped' : selectedInternalSport && selectedInternalSport.id === sport.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                      onClick={() => handleInternalSportClick(sport)}
                    >
                      <p>ID: {sport.id}</p>
                      <p>Name: {sport.name}</p>
                      {/* Conditionally display the provider ID if it exists */}
                      {mappedProviderSport && (
                        <p>{selectedProvider.name} ID: {mappedProviderSport.id}</p>
                      )}

                    </div>
                  );
                })}
              </div>
            )}
            </div>
          </div>
        </div>
        {existingLinkedSports.length > 0 && (
          <div className="linked-section">
            <div className="linked-section-header">
              <h3>Existing Linked Sports</h3>
              <button className={theme === 'dark-mode' ? 'delete-existing-link-button dark-mode' : 'delete-existing-link-button light-mode'} onClick={handleConfirmDeletion} disabled={scheduledForDeletion.length === 0}>
                {"Delete Exisiting Links"}
              </button>
            </div>
            <div className="linked-section-content">
              {existingLinkedSports.map((link, index) => (
                <div className={`linked-item ${selectedDeletionIndexes.find(item => item === index) !== undefined ? 'scheduled' :  ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`} key={index}>
                  <div className="linked-item-details">
                      <p>
                        <b>
                          <i>NBCSports Sport:</i>
                        </b>{" "}
                        {link.internalSportName}
                      </p>
                      <p>
                        <b>
                          <i>{selectedProvider.name} ID:</i>
                        </b>{" "}
                        {link.providerSportId}
                      </p>
                      <p>
                        <b>
                          <i>NBCSports ID:</i>
                        </b>{" "}
                        {link.internalSportId}
                      </p>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleScheduleLinkForDeletionClick(index)}>Delete</button>
                    <button onClick={() => handleUndoScheduleLinkForDeletionClick(index)}>Undo</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="linked-section">
            <div className="linked-section-header">
              <h3>New Links</h3>
              <button className={theme === 'dark-mode' ? 'submit-button dark-mode' : 'submit-button light-mode'} onClick={handleSubmit} disabled={linkedSports.length === 0}>
                Submit
              </button>
            </div>
            <div className="linked-section-content">
              {linkedSports.length === 0 && <p>No linked sports yet.</p>}
              {linkedSports.map((link, index) => (
                <div className="linked-item" key={index}>
                  <div className="linked-item-details">
                    <div>
                      <p><b><i>{selectedProvider.name} Sport:</i></b> {link.providerSport.name}</p>
                      <p><b><i>NBCSports Sport:</i></b> {link.internalSport.name}</p>
                    </div>
                    <div>
                      <p><b><i>{selectedProvider.displayName} ID:</i></b> {link.providerSport.id}</p>
                      <p><b><i>NBCSports ID:</i></b> {link.internalSport.id}</p>
                    </div>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleEditLink(index)}>Edit</button>
                    <button onClick={() => handleDeleteLink(index)}>Delete</button>
                  </div>
                </div>
              ))}
              {editingIndex !== null && (
                <button className="save-edit-button" onClick={handleSaveEdit}>
                  Save Edit
                </button>
              )}
            </div>
        </div>
      </div>

      {/* Modal for duplicate alert */}
      <Modal show={showAlert} onHide={() => setShowAlert(false)} className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sport is already linked. Please select a different pair of sports.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlert(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal 
        isOpen={showErrorModal} 
        onClose={handleErrorModalClose} 
        errorMessages={errorModalMessages} 
        theme={theme} />

      <LoadingModal 
        isOpen={showLoadingModal}
        onClose={() => setShowLoadingModal(false)}
        message={loadingModalMessage}
        theme={theme} />

      <SearchModal 
      isOpen={showSearchModal} 
      onClose={() => {setShowSearchModal(false); setSearchItems([]);}} 
      targetProvider={searchType === 'internal' ? { name: 'NBCSports', id: -1} : selectedProvider}
      searchItems={searchItems}
      theme={theme}
      handleSearchItemSelect={handleSearchItemSelect} />

    </div>
  );
};

export default Sports;
