// src/Events.js
import React, { useState, useEffect } from 'react';
import DropdownMenu from './DropdownMenu'; // Import the reusable dropdown
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Ensure styles are applied
import { Modal, Button, Spinner } from 'react-bootstrap';
import ErrorModal from './ErrorModal';
import SearchModal from './SearchModal';
import LoadingBubbles from './LoadingBubbles';

const Events = ({ theme, getToken, setToken }) => {
    const [dropdownProviders, setDropdownProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [loadingModalMessage, setLoadingModalMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessages, setErrorModalMessages] = useState([]); 
    const [leagues, setLeagues] = useState([]);
    const [scheduledForDeletion, setScheduledForDeletion] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [selectedSport, setSelectedSport] = useState(null);
    const [selectedInternalEvent, setSelectedInternalEvent] = useState(null);
    const [selectedProviderEvent, setSelectedProviderEvent] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [linkedEvents, setLinkedEvents] = useState([]);
    const [existingLinkedEvents, setExistingLinkedEvents] = useState([]);
    const [providerEvents, setProviderEvents] = useState([]);
    const [internalEvents, setInternalEvents] = useState([]);
    const [selectedDeletionIndexes, setSelectedDeletionIndexes] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [noProviderDataMessage, setNoProviderDataMessage] = useState('Please select a provider, league, and season to see events.');
    const [noInternalDataMessage, setNoInternalDataMessage] = useState('');
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [searchItems, setSearchItems] = useState([]);
    const [searchType, setSearchType] = useState(null);
    const [isRendering, setIsRendering] = useState(false);

    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const authorization = 'Bearer ' + getToken();

        // Fetch internal provider data on component mount
        useEffect(() => {
            const requestOptions = {
              method: 'GET',
              headers: {
                'Authorization': authorization
              }
            };
            fetch(BASE_API_URL + '/provider', requestOptions)
            .then(response => {
              if(response.status === 401) {
                setToken(null);
              }
              else if(response.ok) {
                return response.json();
              }
              else {
                throw new Error('Failed to fetch provider data. Please try again later.');
              }
            })
            .then(json => {
                //only set the external providers and the dashboard enabled ones
                const filtered = json.providers.filter(provider => provider.isDashboardEnabled === true && provider.isExternal === true);
                setDropdownProviders(filtered);
            })
            .catch(error => {
              setErrorModalMessages(['Failed to fetch provider data. Please try again later.']);
              setShowErrorModal(true);
            });
          }, []);

          const handleProviderSelect = (provider) => {
            setLoadingModalMessage('Loading league data...');
            setShowLoadingModal(true);
            setSelectedProvider(provider);
            setLeagues([]);
            setScheduledForDeletion([]);
            setSelectedLeague(null);
            setSelectedSport(null);
            setSelectedInternalEvent(null);
            setSelectedProviderEvent(null);
            setLinkedEvents([]);
            setExistingLinkedEvents([]);
            setProviderEvents([]);
            setInternalEvents([]);
            setSelectedDeletionIndexes([]);
            setEditingIndex(null); // Reset editing index on new selection
            setSeasons([]);
            setSelectedSeason(null);
            setIsRendering(false);
            
            //we use a timeout to allow the data to fully load before setting the selected provider
            setTimeout(async () => {
              try {
                // Fetch sports data for the selected provider
                await fetchInternalLeagues(provider.id);
              } catch (error) {
                console.error('Failed to fetch leagues:', error);
              }
              finally {
                setShowLoadingModal(false); // Hide loading modal
                setLoadingModalMessage(''); // Reset loading modal message
              }
            }, 1000);
          };

          const fetchInternalLeagues = async (providerId) => {
            const requestOptions = {
              method: 'GET',
              headers: {
                'Authorization': authorization
              }
            };
        
            fetch(BASE_API_URL + '/provider/league?provider=-1', requestOptions)
            .then(response => {
              if(response.status === 401) {
                setToken(null);
              }
              else if(response.ok) {
                return response.json();
              }
              else {
                throw new Error('Failed to fetch internal league data. Please try again later.');
              }
            })
            .then(json => {
              const existing = json.leagues.filter(league => league.mappings !== undefined && league.mappings?.find(item => item.provider.id === providerId) !== undefined && league.seasons !== undefined);
              if(existing !== undefined && existing.length > 0) {
                const links = [];
                existing.forEach(element => {
                  links.push({id: element.id, name: element.name, providerLeagueId: element.mappings.find(item => item.provider.id === providerId).id, providerLeagueName: element.mappings.find(item => item.provider.id === providerId)?.name, seasons: element.seasons, sport: element.sport, isScheduledForDeletion: false});
                });
                setLeagues(links);
              }
            })
            .catch(error => {
              setErrorModalMessages(['Failed to fetch internal league data. Please try again later.']);
              setShowErrorModal(true);
            });
          };

          const handleLeagueSelect = async (league) => {
            setSelectedLeague(league);
            setSelectedSport(league.sport);
            setSelectedProviderEvent(null); // Reset selected provider Event on new selection
            setSelectedInternalEvent(null); // Reset selected internal Event on new selection
            setProviderEvents([]);
            setInternalEvents([]);
            setScheduledForDeletion([]);
            setExistingLinkedEvents([]);
            setSelectedDeletionIndexes([]);
            setEditingIndex(null); // Reset editing index on new selection
            setLinkedEvents([]);
            setSelectedSeason(null);
            setSeasons([]);
            setIsRendering(false);
            const tempSeasons = [];
            //sort the incoming seasons
            league.seasons.sort();
            //make descending, so latest season is first
            league.seasons.reverse();
            league.seasons.forEach(season => {
              tempSeasons.push({id: season, name: season});
            });
            setSeasons(tempSeasons);
          };

          const handleSeasonSelect = async (season) => {
            setLoadingModalMessage('Loading Event data...');
            setShowLoadingModal(true);
            setSelectedProviderEvent(null); // Reset selected provider Event on new selection
            setSelectedInternalEvent(null); // Reset selected internal Event on new selection
            setProviderEvents([]);
            setInternalEvents([]);
            setScheduledForDeletion([]);
            setExistingLinkedEvents([]);
            setSelectedDeletionIndexes([]);
            setEditingIndex(null); // Reset editing index on new selection
            setLinkedEvents([]);
            setSelectedSeason(season);
            setIsRendering(true);
      
            setTimeout(async () => {
              try {
                // Fetch Events data for the selected provider
                await fetchInternalEvents(selectedProvider.id, selectedLeague, selectedSport, season);
                await fetchProviderEvents(selectedProvider.id, selectedLeague, selectedSport, season);
              } catch (error) {
                console.error('Failed to fetch Events:', error);
              }
              finally {
                setShowLoadingModal(false); // Hide loading modal
                setLoadingModalMessage(''); // Reset loading modal message
              }
            }, 1000);
          }

          const fetchInternalEvents = async (providerId, league, sport, season) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                  'Authorization': authorization
                }
              };
          
              fetch(BASE_API_URL + '/provider/event?provider=-1&season=' + season.id + '&leagueId=' + league.id, requestOptions)
              .then(response => {
                if(response.status === 401) {
                  setToken(null);
                }
                else if(response.ok) {
                  return response.json();
                }
                else if(response.status === 404) {
                    setNoInternalDataMessage('No internal data available for the selected season.');
                }
                else {
                  throw new Error('Failed to fetch internal events data. Please try again later.');
                }
              })
              .then(json => {
                if(json !== undefined && json.events !== undefined){
                    setInternalEvents(json.events);
                    const existing = json.events.filter(event => event.mappings !== undefined && event.mappings?.find(item => item.provider.id === providerId) !== undefined);
                    if(existing !== undefined && existing.length > 0) {
                      const links = [];
                      existing.forEach(element => {
                        links.push({
                            id: element.id,
                            name: element.name, 
                            startDateTimeDisplay: element.startDateTimeDisplay,
                            providerEventId: element.mappings.find(item => item.provider.id === providerId).id, 
                            providerEventName: element.mappings.find(item => item.provider.id === providerId)?.name,
                            isScheduledForDeletion: false});
                      });
                      setExistingLinkedEvents(links);
                    }
                }
              })
              .catch(error => {
                setErrorModalMessages(['Failed to fetch internal event data. Please try again later.']);
                setShowErrorModal(true);
              });
          };

          const fetchProviderEvents = async (providerId, league, sport, season) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                  'Authorization': authorization
                }
              };
        
              //get the provider sport id
              const providerSportId = Number(league.sport.mappings.find(item => item.provider.id === providerId).id);
              const leagueId = Number(league.providerLeagueId);
          
              fetch(BASE_API_URL + '/provider/event?sportId=' + providerSportId + '&provider=' + providerId + '&leagueId=' + leagueId, requestOptions)
              .then(response => {
                if(response.status === 401) {
                  setToken(null);
                }
                else if(response.ok){
                  return response.json();
                }
                else if(response.status === 404) {
                    setNoProviderDataMessage('No provider data available.');
                }
                else {
                  throw new Error('Failed to fetch provider event data. Please try again later.');
                }
              })
              .then(json => {
                if(json !== undefined && json.events !== undefined){
                    setProviderEvents(json.events);
                }
              })
              .catch(error => {
                setErrorModalMessages(['Failed to fetch provider event data. Please try again later.']);
                setShowErrorModal(true);
              });
          };

          const handleAddLink = async () => {   
            if (selectedProviderEvent && selectedInternalEvent) {
                // Check for duplicates
                const isDuplicate = linkedEvents.some(link =>
                  link.providerEvent.id === selectedProviderEvent.id || link.internalEvent.id === selectedInternalEvent.id
                );
                if (!isDuplicate) {
                  setLinkedEvents(prevLinks => [
                    ...prevLinks,
                    { providerEvent: selectedProviderEvent, internalEvent: selectedInternalEvent }
                  ]);
                  setSelectedProviderEvent(null);
                  setSelectedInternalEvent(null);
                } else {
                  setShowAlert(true);
                }
              }
          };

          const handleProviderEventClick = (event) => {
            setSelectedProviderEvent(selectedProviderEvent && selectedProviderEvent.id === event.id ? null : event);
          };
        
          const handleInternalEventClick = (event) => {
            setSelectedInternalEvent(selectedInternalEvent && selectedInternalEvent.id === event.id ? null : event);
          };

          const handleConfirmDeletion = async () => {
            if (scheduledForDeletion.length > 0) {
                setLoadingModalMessage('Deleting linked events data...');
                setShowLoadingModal(true);
                setTimeout(async () => {
                  //transform scheduledForDeletion to the format required by the API
                  const postMessage = [];
        
                  const providerSportId = Number(selectedSport.mappings.find(item => item.provider.id === selectedProvider.id).id);
        
                  for (let i = 0; i < scheduledForDeletion.length; i++) {
                    postMessage.push({ provider: selectedProvider.id, league: Number(selectedLeague.id), internalEventId: Number(scheduledForDeletion[i].id), id: Number(scheduledForDeletion[i].providerEventId), providerLeagueId: Number(selectedLeague.providerLeagueId), providerSportId: providerSportId, season: selectedSeason.id });
                  }
          
                  const requestOptions = {
                    method: 'POST',
                    headers: {
                      'Authorization': authorization,
                      'content-type': 'application/json'
                    },
                    body: JSON.stringify({events: postMessage})
                  };
              
                  fetch(BASE_API_URL + '/provider/event/unassign', requestOptions)
                  .then(response => {
                    if(response.status === 401) {
                      setToken(null);
                    }
                    else if(response.ok) {
                      return response.json();         
                    }
                    else {
                      throw new Error('Failed to delete linked events data. Please try again later.');
                    }
                  })
                  .then(json => {
                    setShowLoadingModal(false); // Hide loading modal
                    setLoadingModalMessage(''); // Reset loading modal message
                    if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
                      setErrorModalMessages(json.failedAssignments);
                      setShowErrorModal(true);
                    }
                    else {
                      handleProviderSelect(selectedProvider);    
                    }
                  })
                  .catch(error => {
                    setErrorModalMessages(['Failed to delete linked events data. Please try again later.']);
                    setShowErrorModal(true);
                  });    
                }, 1000);
              }
          };

          const handleScheduleLinkForDeletionClick = (index) => {
            if(scheduledForDeletion.length > 0) {
              //only add the item if it doesn't already exist in the setScheduledForDeletion array
              const obj = existingLinkedEvents[index];
              const filtered = scheduledForDeletion.filter(item => item.id !== obj.id && item.providerEventId !== obj.providerEventId);
              setScheduledForDeletion([...filtered, obj]);
            }
            else{
              setScheduledForDeletion([existingLinkedEvents[index]])
            }
        
            if(selectedDeletionIndexes !== undefined) {
              //add index if it does not already exist
              const doesExist = selectedDeletionIndexes.find(item => item === index);
              if(doesExist === undefined) {
                selectedDeletionIndexes.push(index);
              }
            }
          }
        
          const handleUndoScheduleLinkForDeletionClick = (index) => {
            if(scheduledForDeletion !== undefined && scheduledForDeletion.length > 0) {
              //get the id for the existing linked sport
              const obj = existingLinkedEvents[index];
              const filtered = scheduledForDeletion.filter(item => item.id !== obj.id && item.providerEventId !== obj.providerEventId);
              //remove the item with the matching id in the setScheduledForDeletion array
              setScheduledForDeletion(filtered);
              if(selectedDeletionIndexes !== undefined) {
                //add index if it does not already exist
                const indexesFiltered = selectedDeletionIndexes.filter(item => item !== index);
                setSelectedDeletionIndexes(indexesFiltered);
              }
            }
          };

          const handleSubmit = async () => {
            if (linkedEvents.length > 0) {
              setLoadingModalMessage('Submitting linked events data...');
              setShowLoadingModal(true);
      
              setTimeout(async () => {
      
                const providerSportId = Number(selectedSport.mappings.find(item => item.provider.id === selectedProvider.id).id);
      
                //transform linkedEvents to the format required by the API
                const postMessage = [];
                for (let i = 0; i < linkedEvents.length; i++) {
                  postMessage.push({ provider: selectedProvider.id, league: Number(selectedLeague.id), internalEventId: Number(linkedEvents[i].internalEvent.id), id: Number(linkedEvents[i].providerEvent.id), providerLeagueId: Number(selectedLeague.providerLeagueId), providerSportId: providerSportId, season: selectedSeason.id });
                }
        
                const requestOptions = {
                  method: 'POST',
                  headers: {
                    'Authorization': authorization,
                    'content-type': 'application/json'
                  },
                  body: JSON.stringify({events: postMessage})
                };
            
                fetch(BASE_API_URL + '/provider/event/assign', requestOptions)
                .then(response => {
                  if(response.status === 401) {
                    setToken(null);
                  }
                  else if(response.ok) {
                    return response.json();     
                  }
                  else {
                    throw new Error('Failed to submit data. Please try again later.');
                  }
                })
                .then(json => {
                  setShowLoadingModal(false); // Hide loading modal
                  setLoadingModalMessage(''); // Reset loading modal message
                  if(json !== undefined && json.failedAssignments !== undefined && json.failedAssignments.length > 0) {
                    setErrorModalMessages(json.failedAssignments);
                    setShowErrorModal(true);
                  }
                  else {
                    handleProviderSelect(selectedProvider);    
                  }
                })
                .catch(error => {
                  setErrorModalMessages(['Failed to submit data. Please try again later.']);
                  setShowErrorModal(true);
                }); 
               }, 1000);
            }
          };
      
          const handleEditLink = (index) => {
            setEditingIndex(index);
            setSelectedProviderEvent(linkedEvents[index].providerEvent);
            setSelectedInternalEvent(linkedEvents[index].internalEvent);
          };
        
          const handleDeleteLink = (index) => {
            setLinkedEvents(prevLinks => prevLinks.filter((_, i) => i !== index));
            if (editingIndex === index) {
              setEditingIndex(null);
              setSelectedProviderEvent(null);
              setSelectedInternalEvent(null);
            }
          };
      
          const handleSaveEdit = () => {
            if (editingIndex !== null) {
              setLinkedEvents(prevLinks => {
                const newLinks = [...prevLinks];
                newLinks[editingIndex] = { providerEvent: selectedProviderEvent, internalEvent: selectedInternalEvent };
                return newLinks;
              });
              setEditingIndex(null);
              setSelectedProviderEvent(null);
              setSelectedInternalEvent(null);
            }
          };

          
      const handleErrorModalClose = () => {
        setShowErrorModal(false);
        setErrorModalMessages([]);
        if(selectedProvider !== undefined){
          handleProviderSelect(selectedProvider);    
        }
      };

      const handleProviderSearch = () => 
        {
          setSearchType('provider');
          //build search items
          providerEvents.forEach(item => {
            //check if the item is mapped in the internal sports list
            var mappedItem = internalEvents?.find(item1 => item1.mappings?.find(item2 => item2.provider !== undefined && item2.provider.id === selectedProvider.id && item2.id === item.id) !== undefined);
            const mappings = [];
            if(mappedItem !== undefined) {
              mappings.push({provider: mappedItem.provider, id: mappedItem.id});
            }
      
            const tempItem = item;
            tempItem.mappings = mappings;
      
            searchItems.push(tempItem);
          });
      
          setShowSearchModal(true);
        };
      
        const handleInternalSearch = () => 
          {
            setSearchType('internal');
            //build search items
            internalEvents.forEach(item => {
              searchItems.push(item);
            });
        
            setShowSearchModal(true);
          };
  
          
    const handleSearchItemSelect = (item) => {
      if(searchType === 'provider'){
        setSelectedProviderEvent(item);
      }
      else if(searchType === 'internal'){
        setSelectedInternalEvent(item);
      }
  
      setSearchItems([]);
      setShowSearchModal(false);
    };


    return (
    <div className={`events-container ${theme}`}>
    <div className="provider-container-header">
        <DropdownMenu
          options={dropdownProviders}
          selectedOption={selectedProvider}
          onSelect={handleProviderSelect}
          theme={theme}
          disabled={false}
        />
        <DropdownMenu
          options={leagues}
          selectedOption={selectedLeague}
          onSelect={handleLeagueSelect}
          theme={theme}
          disabled={leagues.length === 0}
        />
        <DropdownMenu
          options={seasons}
          selectedOption={selectedSeason}
          onSelect={handleSeasonSelect}
          theme={theme}
          disabled={seasons.length === 0}
        />
          <button className={theme === 'dark-mode' ? 'add-link-button dark-mode' : 'add-link-button light-mode'} onClick={handleAddLink} disabled={!selectedProviderEvent || !selectedInternalEvent}>
            Add Link
          </button>
      </div>

      <div className='sports-content'>
        <div className="sports-sections">
            <div className="sports-section dynamic-section">
              <div className='section-header'>
                <h3>{selectedProvider ? selectedProvider.name + ' - Current Feed Only' : 'Select a Provider'}</h3>
                <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'}  onClick={handleProviderSearch} disabled={providerEvents.length === 0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                </svg>              
              </button> 
              </div>
              <div className='section-content'>
                {isRendering && providerEvents.length === 0 && <LoadingBubbles theme={theme} isDropdown={false} />}
                {!isRendering && providerEvents.length === 0 && <p>{noProviderDataMessage}</p>}
                {selectedProvider && selectedLeague && selectedSport && selectedSeason && providerEvents.length > 0 && (
                  <div className="sports-list">
                    {providerEvents.map((event) => { 
                      var mappedInternalEvents = internalEvents?.find(item => item.mappings?.find(item1 => item1.provider !== undefined && item1.provider.id === selectedProvider.id && item1.id === event.id) !== undefined);
                      
                      return (
                        <div
                          key={event.id}
                          className={`sport-item ${mappedInternalEvents !== undefined ? 'mapped' : selectedProviderEvent && selectedProviderEvent.id === event.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                          onClick={() => handleProviderEventClick(event)}
                        >
                          <p>ID: {event.id}</p>
                          <p>Name: {event.name}</p>
                          <p>Start: {event.startDateTimeDisplay}</p>
                          {/* Conditionally display the internal ID if it exists */}
                          {mappedInternalEvents && (
                            <p>NBCSports ID: {mappedInternalEvents.id}</p>
                          )}
                        </div>
                    )})}
                  </div>
                )}
            </div>
            </div>
            <div className="sports-section internal-section">
              <div className='section-header'>
                <h3>NBCSports - Pre-Game Status Only</h3>
                <button className={theme === 'dark-mode' ? 'search-button dark-mode' : 'search-button light-mode'}  onClick={handleInternalSearch} disabled={internalEvents.length === 0}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                  </svg>              
                </button> 
              </div>
              <div className='section-content'>
              {isRendering && providerEvents.length === 0 && <LoadingBubbles theme={theme} isDropdown={false}/>}
              {!isRendering && internalEvents.length === 0 && <p>{noInternalDataMessage}</p>}
              {internalEvents.length > 0 && (
              <div className="sports-list">
                {internalEvents.map((event) => {
                  // Find the mapped provider league for the selected provider
                  const mappedProviderEvent = event.mappings?.find(item => item.provider.id === selectedProvider?.id);

                  return (
                    <div
                      key={event.id}
                      className={`sport-item ${mappedProviderEvent !== undefined ? 'mapped' : selectedInternalEvent && selectedInternalEvent.id === event.id ? 'selected' : ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`}
                      onClick={() => handleInternalEventClick(event)}
                    >
                      <p>ID: {event.id}</p>
                      <p>Name: {event.name}</p>
                      <p>Start: {event.startDateTimeDisplay}</p>
                      {/* Conditionally display the provider ID if it exists */}
                      {mappedProviderEvent && (
                        <p>{selectedProvider.name} ID: {mappedProviderEvent.id}</p>
                      )}
                    </div>
                  );
                })}
              </div>
              )}
              </div>
            </div>
          </div>
          {existingLinkedEvents.length > 0 && (
          <div className="linked-section">
            <div className="linked-section-header">
              <h3>Existing Linked Events</h3>
              <button className={theme === 'dark-mode' ? 'delete-existing-link-button dark-mode' : 'delete-existing-link-button light-mode'} onClick={handleConfirmDeletion} disabled={scheduledForDeletion.length === 0}>
                {"Delete Exisiting Links"}
              </button>
            </div>
            <div className="linked-section-content">
              {existingLinkedEvents.map((link, index) => (
                <div className={`linked-item ${selectedDeletionIndexes.find(item => item === index) !== undefined ? 'scheduled' :  ''} ${theme === 'dark-mode' ? 'dark-mode' : 'light-mode'}`} key={index}>
                  <div className="linked-item-details">
                      <p>
                        <b>
                          <i>NBCSports Event:</i>
                        </b>{" "}
                        {link.name}
                      </p>
                      <p>
                        <b>
                            <i>Start:</i>{" "}
                        </b>
                        {link.startDateTimeDisplay}
                      </p>
                      <p>
                        <b>
                          <i>{selectedProvider.name} ID:</i>
                        </b>{" "}
                        {link.providerEventId}
                      </p>
                      <p>
                        <b>
                          <i>NBCSports ID:</i>
                        </b>{" "}
                        {link.id}
                      </p>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleScheduleLinkForDeletionClick(index)}>Delete</button>
                    <button onClick={() => handleUndoScheduleLinkForDeletionClick(index)}>Undo</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
          <div className="linked-section">
            <div className="linked-section-header">
              <h3>New Links</h3>
              <button className={theme === 'dark-mode' ? 'submit-button dark-mode' : 'submit-button light-mode'} onClick={handleSubmit} disabled={linkedEvents.length === 0}>
                {"Submit"}
              </button>
            </div>
            <div className="linked-section-content">
              {linkedEvents.length === 0 && <p>No linked events yet.</p>}
              {linkedEvents.map((link, index) => (
                <div className="linked-item" key={index}>
                  <div className="linked-item-details">
                    <div>
                      <p><b><i>{selectedProvider.name} Event:</i></b> {link.providerEvent.name}</p>
                      <p><b><i>NBCSports Event:</i></b> {link.internalEvent.name}</p>
                    </div>
                    <div>
                      <p><b><i>{selectedProvider.name} ID:</i></b> {link.providerEvent.id}</p>
                      <p><b><i>NBCSports ID:</i></b> {link.internalEvent.id}</p>
                    </div>
                  </div>
                  <div className="linked-item-actions">
                    <button onClick={() => handleEditLink(index)}>Edit</button>
                    <button onClick={() => handleDeleteLink(index)}>Delete</button>
                  </div>
                </div>
              ))}
              {editingIndex !== null && (
                <button className="save-edit-button" onClick={handleSaveEdit}>
                  Save Edit
                </button>
              )}
            </div>
        </div>


      </div>

      {/* Loading Modal */}
      <Modal
        show={showLoadingModal}
        onHide={() => setShowLoadingModal(false)}
        backdrop="static"
        keyboard={false}
        className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
      >
        <Modal.Header>
          <Modal.Title>{loadingModalMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>        
        </Modal.Body>
      </Modal>

        {/* Modal for duplicate alert */}
        <Modal show={showAlert} onHide={() => setShowAlert(false)} className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}>
            <Modal.Header closeButton>
            <Modal.Title>Duplicate Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Event is already linked. Please select a different pair of events.</p>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <ErrorModal 
        isOpen={showErrorModal} 
        onClose={handleErrorModalClose} 
        errorMessages={errorModalMessages} 
        theme={theme} />
        
      <SearchModal 
        isOpen={showSearchModal} 
        onClose={() => {setShowSearchModal(false); setSearchItems([]);}} 
        targetProvider={searchType === 'internal' ? { name: 'NBCSports', id: -1} : selectedProvider}
        searchItems={searchItems}
        theme={theme}
        handleSearchItemSelect={handleSearchItemSelect} />
    </div>
    );
};

export default Events;