import React from 'react';
import { Modal } from 'react-bootstrap';

const ErrorModal = ({ isOpen, onClose, errorMessages, theme }) => {

    return (
        <div>
            {/* Error Modal */}
            <Modal
                show={isOpen}
                onHide={onClose}
                className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='error-list'>
                        {
                            errorMessages.map((message, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='error-item'
                                    >
                                        <p>{message}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
 }

export default ErrorModal;
