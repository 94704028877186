// src/DropdownMenu.js
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingBubbles from './LoadingBubbles';

const DropdownMenu = ({ options, selectedOption, onSelect, theme, disabled }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className={theme}
        disabled={disabled}
      >
      {disabled===true ? <LoadingBubbles theme={theme} isDropdown={true}/> : selectedOption ? selectedOption.name : 'Select an Option' }
      </Dropdown.Toggle>

      <Dropdown.Menu className={theme}>
        {options.map((option) => (
          <Dropdown.Item
            key={option.id}
            onClick={() => onSelect(option)}
          >
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
