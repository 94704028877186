import React, { useState } from 'react';
import Tabs from './Tabs';
import './App.css';
import Login from './Login';

const AppContent = () => {
  const [theme, setTheme] = useState('dark-mode');
  const [token, setToken] = useState(null);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark-mode' ? 'light-mode' : 'dark-mode'));
  };

  const setTokenAndCache = (token) => {
    if(token === null){
      sessionStorage.removeItem('nbcsports-merge-ui-token');
    }
    else {
      sessionStorage.setItem('nbcsports-merge-ui-token', token);
    }

    setToken(token);
  }

  const getToken = () => {
    const cachedToken = sessionStorage.getItem('nbcsports-merge-ui-token');
    return cachedToken !== undefined && cachedToken !== null ? cachedToken : token;
  }

  if (!getToken() && process.env.REACT_APP_ENV_NAME !== 'LOCAL') {
    return <Login theme={theme} setToken={setTokenAndCache} />;
  }

  return (
    <div className={`app-container ${theme}`}>
      <header className={`app-header ${theme}`}>
        <h1>NBC Sports Middletier Merge Tool - {process.env.REACT_APP_ENV_NAME}</h1>
        <button onClick={toggleTheme} className="btn-dark-mode">
          <i className={theme === 'dark-mode' ? 'fas fa-sun' : 'fas fa-moon'}></i>
        </button>
      </header>
      <Tabs theme={theme} getToken={getToken} setToken={setTokenAndCache} token={token}/>
    </div>
  );
};

const App = () => (
  <AppContent />
);

export default App;
