// src/Tabs.js
import React, { act, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Import the CSS file
import Sports from './Sports';
import Leagues from './Leagues';
import Teams from './Teams';
import Events from './Events';
import Markets from './Markets';

const Tabs = ({ theme, getToken, setToken }) => {
  const [activeTab, setActiveTab] = useState('sports');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={`main-content d-flex flex-column h-100 ${theme}`}>
      <ul className={`nav nav-tabs mb-2 header ${theme}`} role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'sports' ? 'active' : ''}`}
            onClick={() => handleTabClick('sports')}
            role="tab"
            aria-controls="sports"
            aria-selected={activeTab === 'sports'}
            href="#"
          >
            Sports
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'leagues' ? 'active' : ''}`}
            onClick={() => handleTabClick('leagues')}
            role="tab"
            aria-controls="leagues"
            aria-selected={activeTab === 'leagues'}
            href="#"
          >
            Leagues
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'teams' ? 'active' : ''}`}
            onClick={() => handleTabClick('teams')}
            role="tab"
            aria-controls="teams"
            aria-selected={activeTab === 'teams'}
            href="#"
          >
            Teams
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'events' ? 'active' : ''}`}
            onClick={() => handleTabClick('events')}
            role="tab"
            aria-controls="events"
            aria-selected={activeTab === 'events'}
            href="#"
          >
            Events
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'markets' ? 'active' : ''}`}
            onClick={() => handleTabClick('markets')}
            role="tab"
            aria-controls="markets"
            aria-selected={activeTab === 'markets'}
            href="#"
          >
            Markets
          </a>
        </li>
      </ul>
      <div className="tab-content flex-fill">
        {activeTab === 'sports' && (
          <div className="tab-pane fade show active" role="tabpanel" id="sports">
            <Sports theme={theme} getToken={getToken} setToken={setToken} />
          </div>
        )}
        {activeTab === 'leagues' && (
          <div className="tab-pane fade show active" role="tabpanel" id="leagues">
            <Leagues theme={theme} getToken={getToken} setToken={setToken}/>
          </div>
        )}
        {activeTab === 'teams' && (
          <div className="tab-pane fade show active" role="tabpanel" id="teams">
            <Teams theme={theme} getToken={getToken} setToken={setToken}/>
          </div>
        )}
        {
          activeTab === 'events' && (
            <div className="tab-pane fade show active" role="tabpanel" id="events">
              <Events theme={theme} getToken={getToken} setToken={setToken}/>
            </div>
          )
        }
        {
          activeTab === 'markets' && (
            <div className="tab-pane fade show active" role="tabpanel" id="markets">
              <Markets theme={theme} getToken={getToken} setToken={setToken}/>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Tabs;
