import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const SearchModal = ({ isOpen, onClose, targetProvider, searchItems, theme, handleSearchItemSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter the items based on search input
  const filteredItems = searchItems.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleItemClick = (item) => {
    setSearchTerm('');  
    handleSearchItemSelect(item);
  };

  return (
    <div>
      {/* Search Modal */}
      <Modal
        show={isOpen}
        onHide={() => {setSearchTerm(''); onClose();}}
        className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="search-bar-container">
            {/* Search bar */}
            <input 
                type="search" 
                className={'form-control' + (theme === 'dark-mode' ? ' dark-mode' : '')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </div>
        {/* Render filtered items */}
        <div className='search-list'>
            {
                filteredItems.map(item => {
                    return (
                      <div
                        key={item.id}
                        className={`search-item ${item.mappings !== undefined && item.mappings !== null && item.mappings.length > 0 ? 'mapped' : ''} ${theme === 'dark-mode' ? 'dark-mode' : ''}`}
                        onClick={() => handleItemClick(item)}
                      >
                        <span>{item.name}</span>
                        <span> {targetProvider.name} ID: {item.id}</span>
                        {/* Conditionally display mapping IDs if they exist */}
                        {item.mappings?.length > 0 && item.mappings.map((mapping, idx) => (
                          <span key={idx}>{mapping.provider.name} ID: {mapping.id}</span>
                        ))}
                      </div>                    
                      )
                })
            }
        </div>
        </Modal.Body>
      </Modal> 
    </div>
  );
};

export default SearchModal;
