import React, { useState, useEffect } from 'react';
import DropdownMenu from './DropdownMenu'; // Import the reusable dropdown
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Ensure styles are applied
import { Modal, Button, Spinner } from 'react-bootstrap';
import ErrorModal from './ErrorModal';
import LoadingBubbles from './LoadingBubbles';
import MarketSearchModal from './MarketSearchModal';

const Markets = ({theme, getToken, setToken}) => {
    const [dropdownProviders, setDropdownProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [loadingModalMessage, setLoadingModalMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessages, setErrorModalMessages] = useState([]); 
    const [leagues, setLeagues] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [selectedSport, setSelectedSport] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [internalMarkets, setInternalMarkets] = useState([]); 
    const [noInternalDataMessage, setNoInternalDataMessage] = useState('Please select a provider, league, and season to see markets. ');
    const [expandedSections, setExpandedSections] = useState({});
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [searchItems, setSearchItems] = useState([]);
    const [searchType, setSearchType] = useState(null);
    const [isRendering, setIsRendering] = useState(false);

    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const authorization = 'Bearer ' + getToken();

            // Fetch internal provider data on component mount
            useEffect(() => {
                const requestOptions = {
                  method: 'GET',
                  headers: {
                    'Authorization': authorization
                  }
                };
                fetch(BASE_API_URL + '/provider', requestOptions)
                .then(response => {
                  if(response.status === 401) {
                    setToken(null);
                  }
                  else if(response.ok) {
                    return response.json();
                  }
                  else {
                    throw new Error('Failed to fetch provider data. Please try again later.');
                  }
                })
                .then(json => {
                    //only set the external providers and the dashboard enabled ones
                    const filtered = json.providers.filter(provider => provider.isDashboardEnabled === true && provider.isExternal === true);
                    setDropdownProviders(filtered);
                })
                .catch(error => {
                  setErrorModalMessages(['Failed to fetch provider data. Please try again later.']);
                  setShowErrorModal(true);
                });
              }, []);

              const handleProviderSelect = (provider) => {
                setLoadingModalMessage('Loading League data...');
                setShowLoadingModal(true);
                setSelectedProvider(provider);
                setLeagues([]);
                setSelectedLeague(null);
                setSelectedSport(null);
                setSeasons([]);
                setInternalMarkets([]);
                setSelectedSeason(null);
                setIsRendering(false);
                
                //we use a timeout to allow the data to fully load before setting the selected provider
                setTimeout(async () => {
                  try {
                    // Fetch sports data for the selected provider
                    await fetchInternalLeagues(provider.id);
                  } catch (error) {
                    console.error('Failed to fetch leagues:', error);
                  }
                  finally {
                    setShowLoadingModal(false); // Hide loading modal
                    setLoadingModalMessage(''); // Reset loading modal message
                  }
                }, 1000);
              };

              const fetchInternalLeagues = async (providerId) => {
                const requestOptions = {
                  method: 'GET',
                  headers: {
                    'Authorization': authorization
                  }
                };
            
                fetch(BASE_API_URL + '/provider/league?provider=-1', requestOptions)
                .then(response => {
                  if(response.status === 401) {
                    setToken(null);
                  }
                  else if(response.ok) {
                    return response.json();
                  }
                  else {
                    throw new Error('Failed to fetch internal league data. Please try again later.');
                  }
                })
                .then(json => {
                  const existing = json.leagues.filter(league => league.mappings !== undefined && league.mappings?.find(item => item.provider.id === providerId) !== undefined && league.seasons !== undefined);
                  if(existing !== undefined && existing.length > 0) {
                    const links = [];
                    existing.forEach(element => {
                      links.push({id: element.id, name: element.name, providerLeagueId: element.mappings.find(item => item.provider.id === providerId).id, providerLeagueName: element.mappings.find(item => item.provider.id === providerId)?.name, seasons: element.seasons, sport: element.sport, isScheduledForDeletion: false});
                    });
                    setLeagues(links);
                  }
                })
                .catch(error => {
                  setErrorModalMessages(['Failed to fetch internal league data. Please try again later.']);
                  setShowErrorModal(true);
                });
              };

              const handleLeagueSelect = async (league) => {
                setSelectedLeague(league);
                setSelectedSport(league.sport);
                setSelectedSeason(null);
                setSeasons([]);
                setInternalMarkets([]);
                setIsRendering(false);
                const tempSeasons = [];
                //sort the incoming seasons
                league.seasons.sort();
                //make descending, so latest season is first
                league.seasons.reverse();
                league.seasons.forEach(season => {
                  tempSeasons.push({id: season, name: season});
                });
                setSeasons(tempSeasons);
              };

              const handleSeasonSelect = async (season) => {
                setLoadingModalMessage('Loading Market data...');
                setShowLoadingModal(true);
                setSelectedSeason(season);
                setInternalMarkets([]);
                setIsRendering(true);
          
                setTimeout(async () => {
                  try {
                    // Fetch Events data for the selected provider
                    await fetchInternalMarkets(selectedProvider.id, selectedLeague, selectedSport, season);
                  } catch (error) {
                    console.error('Failed to fetch Markets:', error);
                  }
                  finally {
                    setShowLoadingModal(false); // Hide loading modal
                    setLoadingModalMessage(''); // Reset loading modal message
                  }
                }, 1000);
              };

              const handleSyncMarkets = async () => {
                setLoadingModalMessage('Syncing markets...');
                setShowLoadingModal(true);
                setIsRendering(true);
                setInternalMarkets([]);
                setTimeout(async () => {
                  try {
                    await syncMarkets(selectedProvider.id, selectedLeague);
                    // Fetch Events data for the selected provider
                    await fetchInternalMarkets(selectedProvider.id, selectedLeague, selectedSport, selectedSeason);
                  } catch (error) {
                    console.error('Failed to sync Markets:', error);
                  }
                  finally {
                    setShowLoadingModal(false); // Hide loading modal
                    setLoadingModalMessage(''); // Reset loading modal message
                  }
                }, 1000);
              };

              const handleInternalSearch = async () => {};  

              const syncMarkets = async (providerId, league) => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                      'Authorization': authorization
                    }
                  };
              
                  fetch(BASE_API_URL + '/provider/market/sync?provider=' + providerId + '&league=' + league.id, requestOptions)
                  .then(response => {
                    if(response.status === 401) {
                      setToken(null);
                    }
                    else if(response.ok) {
                        return;
                    }
                    else {
                      throw new Error('Failed to sync market data. Please try again later.');
                    }
                  })
                  .catch(error => {
                    setErrorModalMessages(['Failed to sync market data. Please try again later.']);
                    setShowErrorModal(true);
                  });
              };

              const fetchInternalMarkets = async (providerId, league, sport, season) => 
                {
                    const requestOptions = {
                        method: 'GET',
                        headers: {
                          'Authorization': authorization
                        }
                      };
                  
                      fetch(BASE_API_URL + '/provider/market?provider=-1&season=' + season.id + '&leagueId=' + league.id, requestOptions)
                      .then(response => {
                        if(response.status === 401) {
                          setToken(null);
                        }
                        else if(response.ok) {
                          return response.json();
                        }
                        else if(response.status === 404) {
                            setErrorModalMessages(['No markets available for the selected season.']);
                            setShowErrorModal(true);
                        }
                        else {
                          throw new Error('Failed to fetch internal markets data. Please try again later.');
                        }
                      })
                      .then(json => {
                        if(json !== undefined && json.markets !== undefined){
                            const targetMarkets = json.markets.filter(market => market.provider !== undefined && market.provider.id === providerId);

                            if(targetMarkets !== undefined && targetMarkets.length > 0) {
                                //group the markets by event id
                                const grouped = targetMarkets.reduce((acc, market) => {
                                    const key = market.eventId;
                                    if (!acc[key]) {
                                      acc[key] = [];
                                    }
                                    acc[key].push(market);
                                    return acc;
                                  }, {});
                                  const markets = [];
                                  for (const key in grouped) {
                                    if (grouped.hasOwnProperty(key)) {
                                      const elements = grouped[key];
                                      if(elements !== undefined && elements !== null && elements.length > 0) {
                                        const event = {};
                                        event.id = elements[0].eventId;
                                        event.title = elements[0].eventTitle;
                                        event.startDateTimeDisplay = elements[0].startDateTimeDisplay;
                                        event.markets = [];

                                        //group the market types together
                                        const groupedMarkets = elements.reduce((acc, market) => {
                                          const key = market.marketType;
                                          if (!acc[key]) {
                                            acc[key] = [];
                                          }
                                          acc[key].push(market);
                                          return acc;
                                        }, {});

                                        for (const marketKey in groupedMarkets) {
                                            if(groupedMarkets.hasOwnProperty(marketKey)) {
                                                const marketElements = groupedMarkets[marketKey];
                                                if(marketElements !== undefined && marketElements !== null && marketElements.length > 0) {
                                                    const market = {};
                                                    market.marketType = marketElements[0].marketType;
                                                    const marketOptions = [];
                                                    marketElements.forEach(option => {
                                                        marketOptions.push({team: option.team, odds: option.displayOdds, value: option.displayValue});
                                                    });
                                                    market.options = marketOptions;
                                                    event.markets.push(market);
                                                }
                                            }
                                        }
                                        markets.push(event);
                                      }
                                    }
                                  }

                                  setInternalMarkets(markets);
                                  setSearchItems(markets);
                            }
                        }
                      })
                      .catch(error => {
                        setErrorModalMessages(['Failed to fetch market data. Please try again later.']);
                        setShowErrorModal(true);
                      });

                };

                // Toggle the visibility of a market section
                const toggleSection = (eventId) => {
                    setExpandedSections((prev) => ({
                    ...prev,
                    [eventId]: !prev[eventId],
                    }));
                };

                const handleErrorModalClose = () => {
                    setShowErrorModal(false);
                    setErrorModalMessages([]);
                    if(selectedProvider !== undefined){
                      handleProviderSelect(selectedProvider);    
                    }
                  };

                return (
                    <div className={`markets-container ${theme}`}>
                    <div className="provider-container-header">
                        <DropdownMenu
                        options={dropdownProviders}
                        selectedOption={selectedProvider}
                        onSelect={handleProviderSelect}
                        theme={theme}
                        disabled={false}
                        />
                        <DropdownMenu
                        options={leagues}
                        selectedOption={selectedLeague}
                        onSelect={handleLeagueSelect}
                        theme={theme}
                        disabled={leagues.length === 0}
                        />
                        <DropdownMenu
                        options={seasons}
                        selectedOption={selectedSeason}
                        onSelect={handleSeasonSelect}
                        theme={theme}
                        disabled={seasons.length === 0}
                        />
                        <button className={theme === 'dark-mode' ? 'add-link-button dark-mode' : 'add-link-button light-mode'} onClick={handleSyncMarkets} disabled={!selectedSeason}>
                            Sync Markets
                        </button>
                    </div>
                    <div className="sports-content">
                        <div className="sports-sections">
                          <div className="sports-section internal-section">
                            <div className="section-header">
                              <h3>Stored Markets</h3>
                              <button
                                className={
                                  theme === 'dark-mode'
                                    ? 'search-button dark-mode'
                                    : 'search-button light-mode'
                                }
                                onClick={() => setShowSearchModal(true)}
                                disabled={internalMarkets.length === 0}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-search"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                              </button>
                            </div>
                            <div className="section-content">
                              {isRendering && internalMarkets.length === 0 && (
                                <LoadingBubbles theme={theme} isDropdown={false} />
                              )}
                              {!isRendering && internalMarkets.length === 0 && (
                                <p>{noInternalDataMessage}</p>
                              )}
                              {internalMarkets.length > 0 && (
                                <div className="sports-list">
                                  {internalMarkets.map((event) => (
                                    <div
                                      className={`sport-item ${
                                        theme === 'dark-mode' ? 'dark-mode' : 'light-mode'
                                      }`}
                                      key={event.id}
                                    >
                                      {/* Collapsible Header */}
                                      <div
                                        className="collapsible-header"
                                        onClick={() => toggleSection(event.id)}
                                      >
                                        <p>{event.title}</p>
                                        <p>{event.startDateTimeDisplay}</p>
                                      </div>
                
                                      {/* Collapsible Content */}
                                      <div
                                        className={`collapsible-content ${
                                          expandedSections[event.id] ? 'expanded' : ''
                                        }`}
                                      >
                                        {event.markets.map((market) => (
                                          <div className="market" key={market.marketType}>
                                            <p className="marketType">{market.marketType}</p>
                                            <div className="teams">
                                              {market.options.map((option, index) => (
                                                <p key={index} className="team">
                                                  {option.team}
                                                </p>
                                              ))}
                                            </div>
                                            <div className="odds-values">
                                              {market.options.map((option, index) => (
                                                <div key={index} className="odds-value">
                                                  <p>Odds: {option.odds}</p>
                                                  <p>Value: {option.value}</p>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                        {/* Loading Modal */}
                        <Modal
                            show={showLoadingModal}
                            onHide={() => setShowLoadingModal(false)}
                            backdrop="static"
                            keyboard={false}
                            className={theme === 'dark-mode' ? 'modal-dark' : 'modal-light'}
                        >
                            <Modal.Header>
                            <Modal.Title>{loadingModalMessage}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>        
                            </Modal.Body>
                        </Modal>

                        <ErrorModal 
                            isOpen={showErrorModal} 
                            onClose={handleErrorModalClose} 
                            errorMessages={errorModalMessages} 
                            theme={theme} />

                        <MarketSearchModal 
                            isOpen={showSearchModal}
                            onClose={() => {setShowSearchModal(false); setSearchItems(internalMarkets);}}
                            marketsData={searchItems}
                            theme={theme} />
                    </div>
                  );
 };

export default Markets;